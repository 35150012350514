import { useContext, useEffect, useState } from "react"
import { Col, Container, Form, Row } from "react-bootstrap"
import { useLocation, useNavigate } from "react-router-dom"
import styled from "styled-components"
import { AppContext } from "../contexts/app-context"
import authentication from "../core/authentication"
import CustomClickableButton from "./custom-components/custom-clickable-button"
import "../../src/index.css"
import { RECOVER_PASSWORD } from "../graphql/graphql-queries"
import { useMutation } from "@apollo/client"
import CustomAlert from "./custom-components/custom-alert"
import ValidationError from "./validation-error"
import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid"
import UploadPreview from "./upload-preview"

interface SignInProps extends React.ComponentProps<any> {
  className?: string
}

const SignIn: React.FC<SignInProps> = ({ className }) => {
  const { dispatch, state } = useContext(AppContext)
  const { isAuthenticated } = state
  const [loading, setLoading] = useState(false)
  const [loginUser, setLoginUser] = useState({
    email: "",
    password: "",
  })
  const [showSignInAfterSignUpMessage, setShowSignInAfterSignUpMessage] =
    useState(false)
  const [
    showSignInAfterResetPasswordMessage,
    setShowSignInAfterResetPasswordMessage,
  ] = useState(false)

  const navigate = useNavigate()
  const location: any = useLocation()
  const [loggedInUser, setLoggedInUser] = useState<any>(null)

  const [reGeneratePassword, setRecoverPassword] = useState(false)
  const [recoverPasswordNotification, setRecoverPasswordNotification] =
    useState("")
  const [errorNotification, setErrorNotification] = useState("")
  let user = window.sessionStorage.getItem("user")

  useEffect(() => {
    ;(async () => {
      if (loggedInUser) {
        window.location.href = "/"
      }
    })()
  }, [isAuthenticated, loggedInUser, location, navigate])

  useEffect(() => {
    if (user) {
      navigate("/")
    }
  }, [])

  const handleSignInClick = async () => {
    let errorss = validationForm()
    if (!isValid(errorss)) {
      setError(errorss)
      return
    }

    if (loading) {
      return
    }

    try {
      setLoading(true)
      // console.log("Here at login")
      await authentication.login({
        username: loginUser.email,
        password: loginUser.password,
      })
      const data = await authentication.getUserData()

      setLoggedInUser(data)

      dispatch({
        type: "set",
        value: {
          accessToken: authentication.getAccessToken(),
          isAuthenticated: true,
          ...data,
        },
      })

      state.isAuthenticated = true
      state.accessToken = authentication.getAccessToken()!
      state.ready = true
      setLoading(false)
    } catch (e: any) {
      //  Notification.toast(
      //    'Please check your email and password.',
      //    'Login Failed'
      //  )
      if (e.name === "Error") {
        setErrorNotification("Invalid email and/or password")
      }
      // console.log("USER NAME AND PASSWRD DO NOT MATCH")
      setLoading(false)
    }
  }

  const [recoverYourPassword] = useMutation(RECOVER_PASSWORD, {
    onCompleted: async (data) => {
      //add notification variable
      setRecoverPasswordNotification(
        "Please check your email to recover the password"
      )
    },
    onError(err) {
      setErrorNotification(err.message)
    },
  })

  const recoverPassword = () => {
    setRecoverPassword(true)
  }
  const signInWithPassword = () => {
    setRecoverPassword(false)
  }
  const handlePasswordRecovery = () => {
    let errorss = validationForm()
    if (!isValid(errorss)) {
      setError(errorss)
      return
    } else {
      recoverYourPassword({
        variables: {
          data: { email: loginUser.email },
        },
      })
      setError({ email: "", password: "" })
    }
  }
  const handleCloseNotification = () => {
    setRecoverPasswordNotification("")
    setErrorNotification("")
  }
  const handleChangeForInput = (e: any) => {
    setLoginUser({
      ...loginUser,
      [e.target.name]: e.target.value,
    })
  }

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault()
        handleSignInClick()
      }
    }

    document.addEventListener("keydown", keyDownHandler)

    return () => {
      document.removeEventListener("keydown", keyDownHandler)
    }
  })

  const [error, setError] = useState({
    email: "",
    password: "",
  })

  const validationForm = () => {
    let errors = {
      email: "",
      password: reGeneratePassword ? "Test123" : "",
    }

    errors.email = validateEmail(loginUser.email)!
    errors.password = validatePassword(loginUser.password)!
    return errors
  }

  const isValid = (errors: any) => {
    let keys = Object.keys(errors)
    let count = keys.reduce((acc, curr) => (errors[curr] ? acc + 1 : acc), 0)
    return count === 0
  }

  const validateEmail = (email: string) => {
    if (email === "") {
      return "Email is required"
    } else if (
      !new RegExp(
        "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
      ).test(email)
    ) {
      return "You need to specify a valid email address"
    }
    return ""
  }

  const validatePassword = (password: string) => {
    if (password === "") {
      return "Password is required"
    }
    return ""
  }

  useEffect(() => {
    if (location?.state?.signedUp) {
      setShowSignInAfterSignUpMessage(true)
      navigate(location.pathname, {})
    } else if (location?.state?.resetPasswordDone) {
      setShowSignInAfterResetPasswordMessage(true)
      navigate(location.pathname, {})
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const toggle = (currentStatus: boolean) => {
    if (currentStatus === true) {
      return false
    } else {
      return true
    }
  }

  const [visibility, setVisibility] = useState(true)

  const visibilityStatus = () => {
    return setVisibility(toggle(visibility))
  }
  const inputType = visibility ? "password" : "text"

  const icon = visibility ? <EyeIcon /> : <EyeOffIcon />
  const signUpNewUser = () => {
    navigate("/signup")
  }

  return (
    <div className={`${className} signup`}>
      <Container>
        {showSignInAfterSignUpMessage && (
          <Row className="flex-d justify-content-center pb-3 me-5">
            <CustomAlert
              handleOnClose={() => setShowSignInAfterSignUpMessage(false)}
              alertType="success"
              alertMessage="Signup is successful. Please login with your password to continue."
            />
          </Row>
        )}
        {showSignInAfterResetPasswordMessage && (
          <Row className="flex-d justify-content-center pb-3 me-5">
            <CustomAlert
              handleOnClose={() =>
                setShowSignInAfterResetPasswordMessage(false)
              }
              alertType="success"
              alertMessage="Your account password has been recovered successfully. Please login with your new password to continue."
            />
          </Row>
        )}
        {recoverPasswordNotification && (
          <Row className="flex-d justify-content-center pb-3 me-5">
            <CustomAlert
              handleOnClose={handleCloseNotification}
              alertType="success"
              alertMessage={recoverPasswordNotification}
            />
          </Row>
        )}
        {errorNotification && (
          <Row className="flex-d justify-content-center pb-3 me-5">
            <CustomAlert
              handleOnClose={handleCloseNotification}
              alertType="error"
              alertMessage={errorNotification}
            />
          </Row>
        )}
        <Row className="h-100">
          <Col sm={5} className="text-start bg-white d-flex signin_container">
            <div className="signup-container text-white justify-content-center">
              <div className="d-flex justify-content-center align-items-center">
                <a href="https://feeschedulepro.com/">
                  <img
                    src="/Logo.png"
                    width="200"
                    height="40"
                    className="d-inline-block align-items-center"
                    alt="FeeSchedulePro logo"
                  />
                </a>
              </div>
              <p className="fs-1 fw-bold text-white">
                {reGeneratePassword ? "Recover Your Password" : "Sign In"}
              </p>
              <Form autoComplete="off">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label className="fw-bold text-white">
                    E-mail Address
                  </Form.Label>

                  <Form.Control
                    name="email"
                    className="login-input"
                    onChange={handleChangeForInput}
                    placeholder="Enter your email address"
                  />
                  {error.email && (
                    <div className="error_message">
                      <ValidationError errorMessage={error.email} />
                    </div>
                  )}
                </Form.Group>
                {!reGeneratePassword && (
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className="fw-bold text-white">
                      Password
                    </Form.Label>
                    <div className="password_field">
                      <Form.Control
                        className="login-input"
                        type={inputType}
                        name="password"
                        onChange={handleChangeForInput}
                        required
                        placeholder="Enter your password"
                        autoComplete="new-password"
                      />
                      <span className="password-toggle-icon">
                        <i onClick={visibilityStatus}>{icon}</i>
                      </span>
                    </div>
                    {error.password && (
                      <div className="error_message">
                        {" "}
                        <ValidationError errorMessage={error.password} />
                      </div>
                    )}
                  </Form.Group>
                )}
                <Form.Label className="ml-1 bottom_content">
                  New User? Click here to{" "}
                  <a
                    className=" text-primary  fw-bold recover_password "
                    onClick={signUpNewUser}
                  >
                    Sign Up.
                  </a>
                </Form.Label>
                <br />
                <Form.Label className="ml-1 bottom_content">
                  {reGeneratePassword ? (
                    <>
                      Already have an account?{" "}
                      <a
                        className=" text-primary fw-bold recover_password "
                        onClick={signInWithPassword}
                      >
                        Sign In.
                      </a>
                    </>
                  ) : (
                    <>
                      Forgot your password? Click here to{" "}
                      <a
                        className="fw-bold text-primary recover_password"
                        onClick={recoverPassword}
                      >
                        Recover Password.
                      </a>
                    </>
                  )}
                </Form.Label>

                {reGeneratePassword ? (
                  <CustomClickableButton
                    buttonContent="Submit"
                    handleOnClick={handlePasswordRecovery}
                    disable={false}
                    classNaming="btn btn-primary"
                  />
                ) : (
                  <CustomClickableButton
                    buttonContent="SIGN IN"
                    handleOnClick={handleSignInClick}
                    disable={false}
                    classNaming="btn btn-primary"
                  />
                )}
              </Form>
            </div>
          </Col>
          <Col
            sm={6}
            className="d-flex justify-content-center align-items-center"
          >
            <div className="">
              <UploadPreview />
            </div>
          </Col>
          {/* </Col> */}
        </Row>
      </Container>
    </div>
  )
}

export default styled(SignIn)`
  .error_message {
    display: flex;
  }
  .login-input {
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
  }
  .signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #253551;
    background-image: radial-gradient(
      circle at top right,
      #441d90 0%,
      #253551 100%
    );
    padding: 20px;
    width: 85%;
    height: 90vh;
    border-radius: 20px;
    justify-content: center;
    box-sizing: border-box;
    position: relative;
    top: 50%;
    transform: translate(10%, -50%);
  }
  .password_field {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  .password-toggle-icon {
    color: black;
    cursor: pointer;
    width: 15px !important;
    position: relative;
    right: 26px;
  }
  @media only screen and (max-width: 768px) {
    .password-toggle-icon {
      position: relative;
      width: 13px !important;
      right: 29px;
      bottom: 2px;
    }
  }
  @media only screen and (max-width: 575px) {
    .signup-container {
      height: 50vh;
      display: flex;
      flex-direction: column;
    }
    .bottom_content {
      font-size: 0.75rem;
    }
    .login-input {
      width: 100%;
    }
  }
  .login-wrapper {
    min-height: 100vh;
  }

  .recover_password:hover {
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
  }
`
