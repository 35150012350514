import { useEffect, useState } from "react";
import { Row, Col, Spinner, Alert } from "react-bootstrap";
import axios from "axios";
import appConfig from "../core/config";
import authentication from "../core/authentication";
import DropzoneComponent from "./dropzone-component";
import CustomUnclickableButton from "./custom-components/custom-unclickable-button";
import CustomClickableButton from "./custom-components/custom-clickable-button";
import { reloadPageAfter2MsDelay } from "../helper-functions";
import styled from "styled-components";

interface UploadToS3PreviewObjects {
  className?: string;
  title: string;
  fileUploadStatus: any;
  setFileUploadId: React.Dispatch<any>;
  setFileUploadStatus: React.Dispatch<any>;
}

const UploadToS3Preview: React.FC<UploadToS3PreviewObjects> = ({
  className,
  title,
  fileUploadStatus,
  setFileUploadId,
  setFileUploadStatus,
}) => {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [selectedFileUpdatedDetails, setSelectedFileUpdatedDetails] =
    useState<any>(null);
  const [isFileUploadedinS3, setIsFileUploadedinS3] = useState<boolean>(false);
  const [isFileUploadProcessing, setIsFileUploadProcessing] = useState(false);
  const [isUploadButtonDisabled, setIsUploadButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const loggedInUser = authentication.getUserData();
  const guestUser = {
    firstName: "Guest",
    lastName: "User",
    email: "guest_user@feeschedulepro.com",
    role: "CLIENT",
  };

  const handleUpload = async () => {
    setIsUploadButtonDisabled(false);
    setIsFileUploadProcessing(true);
    setIsFileUploadedinS3(true);
  };

  const saveFile = async (file: any) => {
    try {
      const token = authentication.getAccessToken();
      await axios({
        method: "POST",
        url: `${appConfig.apiUrl}/rest/file-upload`,
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify({
          originalName: file.originalFileName,
          assignedName: file.assignedFileName,
          path: file.path,
          loggedInUser: loggedInUser ? loggedInUser : guestUser,
        }),
      }).then((data: any) => {
        setFileUploadId(data?.data?.id);
      });
      setIsFileUploadProcessing(false);
      setFileUploadStatus(true);
      setSuccessMessage("File Uploaded Successfully!");
    } catch (err) {
      console.error("Error: ", err);
      setIsFileUploadProcessing(false);
      setErrorMessage("Some error encountered. Please try again!");
    }
  };

  useEffect(() => {
    if (isFileUploadedinS3 && selectedFileUpdatedDetails) {
      (async () => {
        await saveFile(selectedFileUpdatedDetails);
      })();
    }
  }, [isFileUploadedinS3, selectedFileUpdatedDetails]); 

  useEffect(() => {
    if (errorMessage) {
      setIsFileUploadProcessing(false);
      reloadPageAfter2MsDelay();
    }
  }, [errorMessage]);

  return (
    <div className={className}>
      <Row className="m-auto border rounded p-3 d-flex justify-content-center align-items-center right_panel">
        <h3 className="p-4 d-flex justify-content-center">{title}</h3>
        <Col sm={9}>
          <DropzoneComponent
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            selectedFileUpdatedDetails={selectedFileUpdatedDetails}
            setSelectedFileUpdatedDetails={setSelectedFileUpdatedDetails}
            type=""
            isFileUploadedinS3={isFileUploadedinS3}
            setIsFileUploadedinS3={setIsFileUploadedinS3}
            setIsUploadButtonDisabled={setIsUploadButtonDisabled}
            isFileUploading={isFileUploadProcessing}
            docName="File"
            setErrorMessage={setErrorMessage}
          />
        </Col>
        <Col sm={3}>
          <div className="right-side">
            {isFileUploadProcessing ? (
              <div className="d-flex justify-content-center align-items-center unclick">
                <CustomUnclickableButton
                  buttonContent="Uploading..."
                  disabled={true}
                >
                  {/* <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                /> */}
                </CustomUnclickableButton>
              </div>
            ) : (
              <div className="submit_button">
                {isUploadButtonDisabled ? (
                  <div className="unclick">
                    <CustomUnclickableButton
                      buttonContent="Submit"
                      disabled={true}
                    />
                  </div>
                ) : (
                  <CustomClickableButton
                    buttonContent="Submit"
                    handleOnClick={handleUpload}
                    disable={isUploadButtonDisabled}
                  />
                )}
              </div>
            )}
          </div>
        </Col>
        {successMessage && (
          <Row className="mt-4">
            <Alert variant="success fw-bold">{successMessage}</Alert>
          </Row>
        )}
        {errorMessage && (
          <Row className="mt-4">
            <Alert variant="danger">{errorMessage}</Alert>
          </Row>
        )}
      </Row>
    </div>
  );
};

export default styled(UploadToS3Preview)`
  @media only screen and (max-width: 1194px) {
    .right_panel {
      display: flex !important;
      flex-wrap: wrap;
      flex-direction: column;
      padding: 10px !important;
    }
    .submit_button {
      display: flex !important;
      align-items: center;
      flex-direction: column;
      margin-top: 10px !important;
    }
  }
  @media only screen and (max-width: 575px) {
    .right_panel {
      margin-top: 20px !important;
      width: 90%;
      border-radius: 20px;
    }

    .submit_button {
      margin-top: 10px !important;
    }
  }
`;
