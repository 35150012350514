import React, {
  createContext,
  useReducer,
  Dispatch,
  useCallback,
  useMemo,
} from 'react'
import _ from 'lodash'
import authentication from '../core/authentication'
import { User } from '../types/models'

export interface UpdateAppStateAction {
  type: 'set' | 'notificationSeen' | 'initialize'
  value: any
}

export interface AppState {
  isAuthenticated: boolean
  accessToken?: string
  user?: User
  hasUnreadNotifications: boolean
  ready: boolean
}

export function refreshState() {
  window.dispatchEvent(new CustomEvent('user:refresh'))
}

const reducer = (state: AppState, action: UpdateAppStateAction) => {
  switch (action.type) {
    case 'initialize':
      if (state.ready) {
        return state
      }

      return { ...state, ...action.value, ready: true }

    case 'notificationSeen':
      _.remove(
        state.user?.notifications ?? [],
        (it: any) => it.id === action.value
      )
      return {
        ...state,
        hasUnreadNotifications: !!state.user?.notifications?.length,
      }

    case 'set':
      return { ...state, ...action.value }
  }
}

export interface AppContextProps {
  dispatch: Dispatch<UpdateAppStateAction>
  state: AppState
  refresh: () => any
}

const initial = {
  isAuthenticated: false,
  hasUnreadNotifications: false,
  ready: false,
} as AppState

const AppContext = createContext<AppContextProps>({
  state: initial,
  dispatch: () => undefined,
  refresh: () => undefined,
})

export interface AppContextProviderProps {
  initialState?: AppState,
  children: any
}

const AppContextProvider: React.FC<AppContextProviderProps> = ({
  children,
  initialState = {},
}) => {
  const fullInitialState = {
    ...initial,
    ...initialState,
  }

  const [state, dispatch] = useReducer(reducer, fullInitialState)

  const refresh = useCallback(async () => {
    const data = await authentication.getUserData()
    dispatch({
      type: 'set',
      value: { isAuthenticated: true, ...data },
    })
  }, [dispatch])

  const value = useMemo(
    () => ({
      state,
      dispatch,
      refresh,
    }),
    [state, dispatch, refresh]
  )

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

const AppContextConsumer = AppContext.Consumer

export { AppContext, AppContextProvider, AppContextConsumer }
