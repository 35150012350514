class AppConfig {
  readonly baseURL: string =
    process.env.REACT_APP_BASE_URL ??
    (process.env.NODE_ENV === "production"
      ? "https://app.feeschedulepro.com"
      : "http://localhost:3001")
  readonly title = process.env.REACT_APP_PAGE_TITLE ?? "FeeSchedulePro"
  readonly isProduction = process.env.REACT_APP_NODE_ENV === "production"
  readonly isDevelopment = process.env.REACT_APP_NODE_ENV !== "production"
  readonly version = process.env.REACT_APP_VERSION ?? "development"
  readonly logEvents = process.env.REACT_APP_LOG_EVENTS ?? false
  readonly apiUrl =
    process.env.REACT_APP_API_URL ??
    (process.env.NODE_ENV === "production"
      ? "https://app.feeschedulepro.com"
      : "http://localhost:3000")
  readonly pdfparserUrl =
    process.env.REACT_APP_PDF_PARSER_URL ??
    (process.env.NODE_ENV === "production"
      ? "https://app.feeschedulepro.com"
      : "http://localhost:5000")
  readonly s3DirName = process.env.REACT_APP_DIR_NAME ?? "production/"
  readonly creditsValueLessThan10 = 2500
  readonly creditsValueLess10More15 = 2000
  readonly creditsValueMoreThan15 = 1500
  itemPerPage = 25
}

export default new AppConfig()
