import { Button } from "react-bootstrap";
import styled from "styled-components";

export interface CustomClickableButtonProps extends React.ComponentProps<any> {
  className?: string;
  buttonContent?: string;
  handleOnClick: () => void;
  disable: boolean;
  classNaming?: string;
  children?: any;
}

const CustomClickableButton: React.FC<CustomClickableButtonProps> = ({
  className,
  buttonContent,
  handleOnClick,
  disable,
  children,
}) => {
  return (
    <div className={className}>
      <Button
        variant="primary"
        className={`btn rounded-pill fw-bold btn btn-primary responsive_button`}
        onClick={handleOnClick}
        disabled={disable}
        style={{ width: "" }}
      >
        {children} {buttonContent}
      </Button>
    </div>
  );
};

export default styled(CustomClickableButton)`
  @media only screen and (max-width: 575px) {
    .responsive_button {
      font-size: 0.75rem;
    }
  }
  @media only screen and (max-width: 1194px) {
    .responsive_button {
      font-size: 0.75rem;
    }
  }
`;
