import React from "react"
import { Button, Form, Row, Col, Modal, Container } from "react-bootstrap"
import styled from "styled-components"
import authentication from "../core/authentication"
import axios from "axios"
import { appConfig } from "../core"
import saveAs from "file-saver"

export interface DownloadPopupModalProps extends React.ComponentProps<any> {
  show: boolean
  processing: boolean
  fileUploadName: string
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>
  documentId: string
  onHide: () => void
  className: string
  isSuperFile: boolean
}

const DownloadPopupModal: React.FC<DownloadPopupModalProps> = (props) => {
  const handleCancel = () => {
    props.setShowPopup(false)
    props.setProcessing(false)
  }
  const handleDownload = async () => {
    props.setShowPopup(false)
    try {
      let user = authentication?.getUserData()
      let id = props.documentId

      let UseCreditInput = {
        userId: user?.id,
        operation: "DEBIT",
        docId: id,
      }
      const token = authentication.getAccessToken()
      await axios({
        url: `${appConfig.apiUrl}/rest/get-csv-file`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: { documentId: id, data: UseCreditInput },
        responseType: "arraybuffer",
      })
        .then((response) => {
          if (props.isSuperFile) {
            const blob = new Blob([response.data], {
              type: "application/octet-stream",
            })
            saveAs(blob, `${props.fileUploadName}_converted.zip`)
          } else {
            const blob = new Blob([response.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
            saveAs(blob, `${props.fileUploadName}_converted.csv`)
          }
          props.setProcessing(false)
        })
        .then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 500)
        })
    } catch (error: any) {
      props.setProcessing(false)
      console.error("Error downloading Excel file:", error)
    }
  }

  return (
    <div className={`${props.className} popup-modal`}>
      <Container className=" text-start bg-white text-color">
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Confirmation Message
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="mt-3">
              <Col>
                <strong>
                  Are you sure you want to use 1 credit to download the
                  converted result for {props.fileUploadName}?
                </strong>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Row className="w-100 d-flex justify-content-center">
              <Col sm="auto">
                <Button
                  variant="primary rounded-pill fw-bold"
                  onClick={handleDownload}
                >
                  Download
                </Button>
              </Col>
              <Col sm="auto">
                <Button
                  variant="outline-primary rounded-pill fw-bold"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  )
}

export default styled(DownloadPopupModal)`
  .modal-lg,
  .modal-xl {
    max-width: 30%;
  }
  .modal-content {
    height: -webkit-fill-available;
  }
  .modal-header {
    position: sticky;
  }
  .modal-body {
    height: fit-content;
  }
`
