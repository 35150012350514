import { useMutation } from "@apollo/client"
import { useEffect, useState } from "react"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import styled from "styled-components"
import { UPDATE_USER_PASSWORD } from "../../graphql/graphql-queries"
import CustomClickableButton from "../custom-components/custom-clickable-button"
import { useForm } from "react-hook-form"
import ValidationError from "../validation-error"
import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid"

export interface UserProfileSettingsProps extends React.ComponentProps<any> {
  className?: string
  user: any
  setSuccessMessage: React.Dispatch<React.SetStateAction<string>>
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
  cancelClicked: boolean
  setCancelClicked: React.Dispatch<React.SetStateAction<boolean>>
}

const UserProfileSettings: React.FC<UserProfileSettingsProps> = ({
  className,
  user,
  setSuccessMessage,
  setErrorMessage,
  cancelClicked,
  setCancelClicked,
}) => {
  let initialUserDetails = {
    firstName: user?.firstName ? user?.firstName : "",
    lastName: user?.lastName ? user?.lastName : "",
    password: user?.password ? user?.password : "",
  }
  const [userDetails, setUserDetails] = useState<any>(initialUserDetails)

  useEffect(() => {
    setUserDetails({
      firstName: user?.firstName,
      lastName: user?.lastName,
      password: user?.password,
    })
    setCancelClicked(false)
  }, [user, cancelClicked]) // eslint-disable-line react-hooks/exhaustive-deps

  const {
    register,
    handleSubmit: securityInfoUpdationHandler,
    formState,
    watch,
  } = useForm()
  const onError = (errors: any, e: any) => console.log(errors, e)

  const [userPasswordDetails, setUserPasswordDetails] = useState<any>({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  })

  const handleOnChangePassword = (e: any) => {
    setUserPasswordDetails({
      ...userPasswordDetails,
      [e.target.name]: e.target.value,
    })
  }

  const handleSaveChangesClick = (e: any) => {
    updateUserPassword({
      variables: {
        userId: user?.id,
        data: {
          currentPassword: userPasswordDetails?.currentPassword,
          newPassword: userPasswordDetails?.newPassword,
        },
      },
    })
  }

  const [updateUserPassword] = useMutation(UPDATE_USER_PASSWORD, {
    onCompleted: (data) => {
      setSuccessMessage("Password successfully updated")
    },
    onError(err) {
      if (
        err.message ===
          "Your current password does not the match the password you provided." ||
        err.message ===
          "Your new password cannot be the same as your current password."
      ) {
        setErrorMessage(err.message)
      } else {
        setErrorMessage("Some error encountered. Please try again!")
      }
    },
  })

  useEffect(() => {
    setUserPasswordDetails({
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    })
    setCancelClicked(false)
  }, [cancelClicked])

  const toggle = (currentStatus: boolean) => {
    if (currentStatus === true) {
      return false
    } else {
      return true
    }
  }

  const [visibility, setVisibility] = useState(true)

  const visibilityStatus = () => {
    return setVisibility(toggle(visibility))
  }
  const inputType = visibility ? "password" : "text"

  const icon = visibility ? <EyeIcon /> : <EyeOffIcon />
  return (
    <Container className={`${className} p-5`}>
      <Form className="text-start">
        <Row className="d-flex justify-content-center">
          <p className="fs-5 fw-bold text-primary">Change Password</p>
          <p className="text-muted">
            Your password must be atleast 8 characters long.
          </p>
          <Col sm={3}>
            <Form.Group className="mb-3 w-75" controlId="formBasicFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={userDetails?.firstName ? userDetails?.firstName : ""}
                autoComplete="off"
                readOnly
                disabled
              />
            </Form.Group>
          </Col>
          <Col sm={3}>
            <Form.Group className="mb-3 w-75" controlId="formBasicLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={userDetails?.lastName ? userDetails?.lastName : ""}
                autoComplete="off"
                readOnly
                disabled
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className="mb-3 w-75"
              controlId="formBasicCurrentPassword"
            >
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                {...register("currentPassword", {
                  required: {
                    value: true,
                    message:
                      "You must specify a Password before moving forward.",
                  },
                })}
                type="password"
                value={userPasswordDetails.currentPassword}
                onChange={handleOnChangePassword}
              />
              {formState.errors.currentPassword && (
                <ValidationError
                  errorMessage={formState.errors.currentPassword.message}
                />
              )}
            </Form.Group>
          </Col>
        </Row>

        <Row className="w-50"></Row>
        <Row>
          <Col>
            <Form.Group className="mb-3 w-75" controlId="formBasicNewPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                {...register("newPassword", {
                  required: {
                    value: true,
                    message:
                      "You must specify a New Password before moving forward.",
                  },
                  validate: (val: string) => {
                    if (watch("newPassword").length < 8) {
                      return "Password must be at least 8 characters long!"
                    }
                  },
                })}
                type={inputType}
                value={userPasswordDetails.newPassword}
                onChange={handleOnChangePassword}
              />
              <span
                className={
                  formState.errors.newPassword
                    ? `password-toggle-icon-error`
                    : `password-toggle-icon-new `
                }
              >
                <i onClick={visibilityStatus}>{icon}</i>
              </span>
              {formState.errors.newPassword && (
                <ValidationError
                  errorMessage={formState.errors.newPassword.message}
                />
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group
              className="mb-3 w-75"
              controlId="formBasicConfirmNewPassword"
            >
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                {...register("confirmNewPassword", {
                  required: {
                    value: true,
                    message: "You must confirm password before moving forward.",
                  },
                  validate: (val: string) => {
                    if (watch("newPassword") !== val) {
                      return "Your passwords do no match"
                    }
                  },
                })}
                type="password"
                value={userPasswordDetails.confirmNewPassword}
                onChange={handleOnChangePassword}
              />
              {formState.errors.confirmNewPassword && (
                <ValidationError
                  errorMessage={formState.errors.confirmNewPassword.message}
                />
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4 d-flex d-flex justify-content-center">
          <Col sm="auto" className="text-primary me-2">
            <CustomClickableButton
              buttonContent="Change Password"
              handleOnClick={securityInfoUpdationHandler(
                handleSaveChangesClick,
                onError
              )}
              disable={false}
            />
          </Col>
          <Col sm={2}>
            <Button
              variant="outline-primary rounded-pill fw-bold"
              className="outline-button"
              onClick={() => setCancelClicked(true)}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}

export default styled(UserProfileSettings)`
  div {
    position: relative;
    overflow: hidden;
  }
  .upload-file-input {
    position: absolute;
    font-size: 50px;
    opacity: 0;
    right: 0;
    top: 0;

    &:hover {
      cursor: pointer;
    }
  }

  .contact-input {
    border: 1px solid #ced4da;
    border-radius: 4px;
    width: 470px;
    padding: 5px;
  }

  .outline-button {
    color: black;

    &:hover {
      background: none;
    }
  }
  .password-toggle-icon-new {
    position: absolute;
    top: 49%;
    right: 5%;
    z-index: 1000;
    color: black;
    cursor: pointer;
    width: 10px;
    margin: 0;
    padding: 0;
    scale: 1.5;
  }
  .password-toggle-icon-error {
    position: absolute;
    top: 30%;
    right: 5%;
    z-index: 1000;
    color: black;
    cursor: pointer;
    width: 10px;
    margin: 0;
    padding: 0;
    scale: 1.5;
  }
`
