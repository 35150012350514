import styled from "styled-components"
import authentication from "../core/authentication"
import { useLazyQuery } from "@apollo/client"
import { GET_CREDIT_OF_USER } from "../graphql/graphql-queries"
import { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import moment from "moment"
import config from "../core/config"
import Pagination from "./pagination"

export interface TransactionsProps extends React.ComponentProps<any> {
  className?: string
}

const Transactions: React.FC<TransactionsProps> = ({ className }) => {
  const user = authentication.getUserData()
  // user?.id)
  const [transactions, setTransactions] = useState([])
  const transactionsListArray = transactions
  const [pageNumber, setPageNumber] = useState(1)
  const [count, setCount] = useState(0)
  const offset = 0
  const dataPerPage = config.itemPerPage
  let paginationData = {
    userId: user?.id,
    offset: pageNumber === 1 ? offset : (pageNumber - 1) * 25,
    limit: dataPerPage,
  }
  const [getTransactions] = useLazyQuery(GET_CREDIT_OF_USER, {
    onCompleted: (data) => {
      setTransactions(data?.getCreditOfUser?.creditTransactions)
      setCount(data?.getCreditOfUser?.count)
    },
  })
  useEffect(() => {
    getTransactions({
      variables: {
        data: paginationData,
      },
    })
  }, [pageNumber])
  const perPage = Math.ceil(count / dataPerPage)

  return (
    <div className={className}>
      <Container className=" text-start bg-white ">
        <Row className="fw-bold py-3">
          <Col sm={3}>Document Name</Col>
          <Col sm={3}>Transaction Id</Col>
          <Col sm={1}>Amount($)</Col>
          <Col sm={1}>Balance($)</Col>
          <Col sm={1}>Action</Col>
          <Col sm={1}>Created at</Col>
        </Row>
        {!!transactions?.length ? (
          <>
            {transactionsListArray?.map((transaction: any, index: number) => (
              <Row
                className="border-top d-flex align-items-center py-2"
                key={transaction.id}
              >
                <Col sm={3}>
                  <div>
                    {transaction?.documentId ? transaction?.name : "N/A"}
                  </div>
                </Col>
                <Col sm={3}>
                  <div>{transaction?.transactionId}</div>
                </Col>
                <Col sm={1}>
                  <div>{transaction?.transactCredits}</div>
                </Col>
                <Col sm={1}>
                  <div>{transaction?.postTransactCredits}</div>
                </Col>
                <Col sm={1}>
                  <div>{transaction?.operation}</div>
                </Col>
                <Col sm={1}>
                  <div>
                    {moment(transaction?.createdAt).format("MM/DD/YYYY")}
                  </div>
                </Col>
              </Row>
            ))}
          </>
        ) : (
          <>
            <Row className="border-top">
              <p className="text-center m-3">
                No Transacation have been made yet.
              </p>
            </Row>
          </>
        )}
        {transactions?.length > 0 && (
          <Pagination
            currentPage={pageNumber}
            setCurrentPage={setPageNumber}
            perPage={perPage}
          />
        )}
      </Container>
    </div>
  )
}
export default styled(Transactions)``
