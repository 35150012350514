import styled from "styled-components"
import { useEffect, useState } from "react"
import { useLazyQuery } from "@apollo/client"
import {
  GET_FILE_UPLOAD_BY_ID,
  LIST_PROCEDURE_ROWS,
} from "../graphql/graphql-queries"
import axios from "axios"
import { appConfig } from "../core"
import SpinnerLoading from "./spinner-loading"
import ErrorFetchingData from "./error-fetching-data"
import UploadToS3Preview from "./upload-to-s3-preview"
import { Row, Spinner } from "react-bootstrap"
import CustomAlert from "./custom-components/custom-alert"
import CustomClickableButton from "./custom-components/custom-clickable-button"

interface UploadPreviewProps {
  className?: string
}

const UploadPreview: React.FC<UploadPreviewProps> = ({ className }) => {
  const [fileUploadId, setFileUploadId] = useState("")
  const [documentId, setDocumentId] = useState("")
  const [parserError, setParseError] = useState("")
  const [procedureRows, setProcedureRows] = useState([])
  const [image, setImage] = useState(null)
  const [fileUploadStatus, setFileUploadStatus] = useState(false)
  const [
    getFileUpload,
    { loading: getFileUploadLoading, error: getFileUploadError },
  ] = useLazyQuery(GET_FILE_UPLOAD_BY_ID, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      if (data?.getfileUpload?.documentId !== null) {
        setDocumentId(data?.getfileUpload?.documentId)
      } else {
        setParseError(
          "There seems to be an error while parsing, please try again."
        )
      }
    },
  })
  const [
    getProcedureRows,
    { loading: getProcedureRowsLoading, error: getProcedureRowsError },
  ] = useLazyQuery(LIST_PROCEDURE_ROWS, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setProcedureRows(data?.listProcedureRows)
    },
  })
  const getEncodedImage = async (procedureRows: any) => {
    await axios({
      url: `${appConfig.apiUrl}/rest/get-image-data`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
      },
      data: { procedureRows },
    }).then((res) => {
      setImage(res?.data?.image)
      setFileUploadStatus(false)
    })
  }

  useEffect(() => {
    // 1st useEffect
    if (fileUploadId)
      setTimeout(() => {
        getFileUpload({
          variables: { fileUploadId: fileUploadId },
        })
      }, 30000)
  }, [!!fileUploadId])

  useEffect(() => {
    // 2nd useEffect
    if (documentId) {
      getProcedureRows({
        variables: { documentId: documentId },
      })
    }
  }, [documentId])

  const rowsPresent = procedureRows?.length > 0 ? true : false

  useEffect(() => {
    // 3rd useEffect
    if (procedureRows?.length) {
      getEncodedImage(procedureRows)
    }
  }, [rowsPresent])
  const handleOnCloseNotification = () => {
    setParseError("")
    window.location.href = "/"
  }
  if (getProcedureRowsLoading || getFileUploadLoading)
    return (
      <>
        <SpinnerLoading />
      </>
    )
  if (getProcedureRowsError || getFileUploadError) return <ErrorFetchingData />
  return (
    <div className={className}>
      {parserError && (
        <div className="error-notification">
          <CustomAlert
            handleOnClose={() => handleOnCloseNotification()}
            alertType="error"
            alertMessage={parserError}
          />
        </div>
      )}
      {fileUploadStatus && !image && (
        <div className="generating_icon">
          <Spinner />
          <p className="fw-bold ">Generating...</p>
          <p className="fw-light">
            {" "}
            Please wait for a few minutes while we process the file.
          </p>
        </div>
      )}
      {rowsPresent && image && (
        <div>
          <h4 className="fw-bold text-primary">Result:</h4>
          <div className="position-relative">
            <img
              className="ps-5"
              id="base64image"
              src={`data:image/jpeg;charset=utf-8;base64,${image}`}
              alt="generating results"
            />
            <div className="overlay-text">
              <p className="fw-bold">
                Get your complete results{" "}
                <CustomClickableButton
                  handleOnClick={() => {
                    window.location.href = "/signup"
                  }}
                >
                  Sign Up Now
                </CustomClickableButton>
              </p>
            </div>
          </div>
        </div>
      )}
      {!fileUploadStatus && !rowsPresent && (
        <UploadToS3Preview
          title="Try out now!!!"
          fileUploadStatus={fileUploadStatus}
          setFileUploadStatus={setFileUploadStatus}
          setFileUploadId={setFileUploadId}
        />
      )}
    </div>
  )
}

export default styled(UploadPreview)`
  #base64image {
    width: 800px;
    height: 625px;
  }
  .error-notification {
    position: absolute !important;
    right: 50%;
    top: 10px;
  }
  @media only screen and (max-width: 575px) {
    .generating_icon {
      margin-top: 20% !important;
    }
  }
  .position-relative {
    position: relative;
  }
  .overlay-text {
    position: absolute;
    font-weight: bolder;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.1);
    padding: 28px;
  }
  .overlay-text .fw-bold {
    margin: 0;
  }
  .overlay-text .link {
    font-weight: bolder;
    text-decoration: none;
    color: #007bff;
  }
  .overlay-text .link:hover {
    text-decoration: underline;
  }
`
