import React, { useState } from "react"
import { Button, Form, Row, Col, Modal, Container } from "react-bootstrap"
import styled from "styled-components"
import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from "@apollo/client"
import { useForm } from "react-hook-form"
import ValidationError from "./validation-error"
import { SEND_FEEDBACK_TO_ADMIN } from "../graphql/graphql-queries"
import CustomAlert from "./custom-components/custom-alert"

export interface FeedBackPopupModalProps extends React.ComponentProps<any> {
  show: boolean
  user: any
  fileuploadId: string
  onHide: () => void
  className: string
  refetch?: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>
}

const FeedBackPopupModal: React.FC<FeedBackPopupModalProps> = (props) => {
  const [feedbackText, setFeedBackText] = useState("") // State to store feedback text
  const { register, handleSubmit, formState } = useForm()
  const [feedbackStatus, setFeedbackStatus] = useState(false)
  const [feedbackMessage, setFeedbackMessage] = useState("")
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFeedBackText(e.target.value)
  }
  const [sendFeedBackToAdmin] = useMutation(SEND_FEEDBACK_TO_ADMIN, {
    onCompleted: (data) => {
      setFeedbackStatus(true)
      setFeedbackMessage("Your feedback has been sent to the admin")
    },
  })
  const handleFeedbackSubmit = async () => {
    const feedBackInput = {
      fileUploadId: props.fileuploadId,
      userId: props.user.id,
      userName: props.user.fullName,
      feedbackMessage: feedbackText,
    }
    try {
      await sendFeedBackToAdmin({
        variables: {
          data: feedBackInput,
        },
      }).then(() => {
        setTimeout(() => {
          props.onHide()
        }, 2000)
      })
    } catch (error) {
      console.log("error while sending the feedback", error)
    }
  }

  return (
    <div className={`${props.className} popup-modal`}>
      {feedbackStatus && (
        <Row className="flex-d justify-content-center pb-3 me-5">
          <CustomAlert
            handleOnClose={() => {
              setFeedbackStatus(false)
              setFeedbackMessage("")
            }}
            alertType="success"
            alertMessage={feedbackMessage}
          />
        </Row>
      )}
      <Container className=" text-start bg-white text-color">
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              User Feedback
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="feedBackBasicForm">
                <Row className="mt-3">
                  <Col>
                    <Form.Label>
                      Please provide any additional comments or suggestions.
                    </Form.Label>
                    <Form.Control
                      name="feedbackValue"
                      value={feedbackText}
                      as="textarea"
                      className="feedback-input"
                      onChange={handleInputChange}
                      autoComplete="off"
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <div className="text-end">
              <Button variant="primary" onClick={handleFeedbackSubmit}>
                Submit Feedback
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  )
}

export default styled(FeedBackPopupModal)`
  .modal-lg,
  .modal-xl {
    max-width: 55%;
  }
  .modal-content {
    height: -webkit-fill-available;
  }
  .modal-header {
    position: sticky;
  }
  .modal-body {
    height: fit-content;
  }
  .feedback-input {
    text-align: start;
    min-height: 100px;
    display: flex;
    align-items: center;
    word-wrap: break-word;
    width: 90%;
    border: 1px solid #ccc;
    padding: 8px;
  }
`
