import { Button } from 'react-bootstrap'
import styled from 'styled-components'

export interface CustomUnclickableButtonProps
  extends React.ComponentProps<any> {
  buttonContent: string
  disabled: boolean
  children?: string
}

const CustomUnclickableButton: React.FC<CustomUnclickableButtonProps> = ({
  buttonContent,
  disabled,
  children,
}) => {
  return (
    <div>
      <Button
        variant="primary"
        className={`btn btn-primary rounded-pill fw-bold text-white`}
        disabled
      >
        {children} {buttonContent}
      </Button>
    </div>
  )
}

export default styled(CustomUnclickableButton)``
