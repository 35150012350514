import { useEffect, useState } from "react"
import { Col, Container, Form, Row } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { useMutation, useQuery } from "@apollo/client"
import styled from "styled-components"

import CustomClickableButton from "./custom-components/custom-clickable-button"
import {
  GET_INVITATION_BY_TOKEN,
  RESET_PASSWORD_USER,
  SIGN_UP_USER,
} from "../graphql/graphql-queries"
import ValidationError from "./validation-error"
import moment from "moment"
import CustomAlert from "./custom-components/custom-alert"
import { toLowerCase } from "../helper-functions"

interface SignUpProps extends React.ComponentProps<any> {
  className?: string
}

const SignUp: React.FC<SignUpProps> = ({ className }) => {
  const { register, handleSubmit: signUpHandler, formState, watch } = useForm()
  const [invitationData, setInvitationData] = useState<any>({})
  const [isLinkValid, setIsLinkValid] = useState<any>(undefined)
  const [errorMessage, setErrorMessage] = useState("")
  const params = new URLSearchParams(window.location.search)
  const token = params.get("token")
  const [resetLink, setResetLink] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const [signupError, setSignupError] = useState("")
  const [signUpInput, setSignUpInput] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    role: "",
    firstName: "",
    isChecked: isChecked,
    lastName: "",
    fullName: "",
    officeName: "",
  })

  const navigate = useNavigate()

  const [signUpUser] = useMutation(SIGN_UP_USER, {
    onCompleted: async (data) => {
      navigate("/login", {
        state: {
          signedUp: true,
          resetPasswordDone: false,
        },
      })
    },
    onError: (err) => {
      setSignupError("User already exist with this email!")
    },
  })
  const [resetPassword] = useMutation(RESET_PASSWORD_USER, {
    onCompleted: async (data) => {
      navigate("/login", {
        state: {
          signedUp: false,
          resetPasswordDone: true,
        },
      })
    },
  })
  const addDays = (date: Date, days: number) => {
    let result = new Date(date)
    result.setDate(result.getDate() + days)
    let userTimezoneOffset1 = result.getTimezoneOffset() * 60000
    let normalizedDate = new Date(result.getTime() - userTimezoneOffset1)
    return normalizedDate
  }
  useEffect(() => {
    if (invitationData) {
      if (invitationData?.expired) {
        setIsLinkValid(false)
        navigate("/login")
        return
      }

      if (invitationData?.sentDate) {
        setSignUpInput({
          ...signUpInput,
          email: invitationData?.user?.email,
          role: invitationData?.user?.role,
        })
      }

      if (invitationData?.sentDate) {
        let dateAfterSpecificDays = addDays(invitationData.sentDate, 7)

        let isLinkVisitedDateBeforeExpiryDate = moment(new Date()).isBefore(
          dateAfterSpecificDays
        )

        setIsLinkValid(isLinkVisitedDateBeforeExpiryDate)
      }
    }
  }, [invitationData])
  const { refetch } = useQuery(GET_INVITATION_BY_TOKEN, {
    variables: {
      token,
    },
    skip: !!token,

    onCompleted: (data) => {
      setInvitationData(data.getInvitationByToken)
    },
  })
  useEffect(() => {
    refetch()
  }, [refetch, invitationData])
  useEffect(() => {}, []) // eslint-disable-line react-hooks/exhaustive-deps

  const onError = (errors: any, e: any) => console.log(errors, e)

  const handleSignUpInputChange = (e: any) => {
    if (e.target.name === "isChecked") {
      setSignUpInput({
        ...signUpInput,
        [e.target.name]: e.target.checked,
      })
      setIsChecked(e.target.checked)
    } else if (e.target.name === "email") {
      setSignUpInput({
        ...signUpInput,
        [e.target.name]: toLowerCase(e.target.value),
      })
    } else {
      setSignUpInput({
        ...signUpInput,
        [e.target.name]: e.target.value,
      })
    }
  }
  const handleSignInClick = () => {
    navigate("/login")
  }

  const handleSignUpClick = (e: any) => {
    setSignUpInput({
      ...signUpInput,
      role: "CLIENT",
    })
    if (isChecked) {
      signUpUser({
        variables: {
          data: signUpInput,
        },
      })
    }
    if (!isChecked) {
      setErrorMessage(
        "Please accept all the terms and conditions, before signing up"
      )
    }
  }
  const handleResetClick = (e: any) => {
    setSignUpInput({
      ...signUpInput,
      role: "CLIENT", // Reset password
    })

    resetPassword({
      variables: {
        data: signUpInput,
      },
    })
  }
  // console.log("errorMessage", errorMessage)
  return (
    <div className={`${className} signup`}>
      <Container>
        {signupError && (
          <Row className="d-flex justify-content-center pb-3 me-5">
            <CustomAlert
              handleOnClose={() => {
                setSignupError("")
                navigate("/login")
              }}
              alertType="error"
              alertMessage={"error"}
            />
          </Row>
        )}
        <Row className="signup-container text-white m-auto ">
          <div className="signup_form">
            <div className=" justify-content-center align-items-center">
              <a href="https://feeschedulepro.com/">
                <img
                  src="/Logo.png"
                  width="200"
                  height="40"
                  className="d-inline-block align-items-center"
                  alt="FeeSchedulePro logo"
                />
              </a>
            </div>
            <span>
              <p className="fs-1 fw-bold me-2">
                {resetLink || isLinkValid ? "Recover Your Password" : "Sign Up"}
              </p>
            </span>
            <div className="m-auto form-container">
              <Form className="">
                <div className="">
                  <Row>
                    <Col className="label">
                      <div className="text-left">
                        {!isLinkValid && (
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicFirstName"
                          >
                            <Form.Label className="fw-bold">
                              First Name
                            </Form.Label>
                            <Form.Control
                              {...register("firstName", {
                                required: {
                                  value: true,
                                  message: "First Name is required.",
                                },
                              })}
                              type="text"
                              name="firstName"
                              placeholder="Enter Your First Name"
                              onChange={handleSignUpInputChange}
                              autoComplete="off"
                              className="sign-up-input"
                              required
                            />
                            {formState.errors.firstName && (
                              <span className="error_message">
                                <ValidationError
                                  errorMessage={
                                    formState.errors.firstName.message
                                  }
                                />
                              </span>
                            )}
                          </Form.Group>
                        )}
                      </div>
                    </Col>
                    <Col className="label">
                      <div>
                        {!isLinkValid && (
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicLastName"
                          >
                            <Form.Label className="fw-bold">
                              Last Name
                            </Form.Label>
                            <Form.Control
                              {...register("lastName", {
                                required: {
                                  value: true,
                                  message:
                                    "You must specify a Last Name before moving forward.",
                                },
                              })}
                              type="text"
                              name="lastName"
                              placeholder="Enter Your Last Name"
                              onChange={handleSignUpInputChange}
                              autoComplete="off"
                              className="sign-up-input"
                              required
                            />
                            {formState.errors.lastName && (
                              <span className="error_message">
                                <ValidationError
                                  errorMessage={
                                    formState.errors.lastName.message
                                  }
                                />
                              </span>
                            )}
                          </Form.Group>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col className="label">
                      <div>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className="fw-bold text-start">
                            E-mail Address
                          </Form.Label>
                          <Form.Control
                            {...register("email", {
                              required: {
                                value: true,
                                message:
                                  "You need to provide an email address.",
                              },
                              pattern: {
                                value: new RegExp(
                                  "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
                                ),
                                message:
                                  "You need to specify a valid email address.",
                              },
                            })}
                            placeholder="Enter Your Email"
                            type="text"
                            name="email"
                            value={signUpInput.email}
                            onChange={handleSignUpInputChange}
                            autoComplete="off"
                            className="sign-up-input "
                            required
                          />
                          {formState.errors.email && (
                            <span className="error_message">
                              <ValidationError
                                errorMessage={formState.errors.email.message}
                              />
                            </span>
                          )}
                        </Form.Group>
                      </div>
                    </Col>
                    <Col className="label">
                      <div>
                        <Form.Group
                          className="mb-3"
                          controlId="formBasicOfficeName"
                        >
                          <Form.Label className="text-start">
                            <p className="m-0">
                              <span className="fw-bold">Office Name </span>
                              (*optional)
                            </p>
                          </Form.Label>
                          <Form.Control
                            placeholder="Enter Your Office Name"
                            type="text"
                            width={100}
                            name="officeName"
                            onChange={handleSignUpInputChange}
                            autoComplete="off"
                            className="sign-up-input "
                          />
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div>
                  <Row>
                    <Col className="label">
                      <div>
                        <Form.Group
                          className="mb-1"
                          controlId="formBasicPassword"
                        >
                          <Form.Label className="fw-bold">Password</Form.Label>
                          <Form.Control
                            {...register("password", {
                              required: {
                                value: true,
                                message:
                                  "You must specify a Password before moving forward.",
                              },
                              validate: (val: string) => {
                                if (watch("password").length < 8) {
                                  return "Password must be at least 8 characters long!"
                                }
                              },
                            })}
                            placeholder="Enter Your Password"
                            className="sign-up-input"
                            type="password"
                            name="password"
                            // ref={password}
                            required
                            onChange={handleSignUpInputChange}
                          />
                          {formState.errors.password && (
                            <span className="error_message">
                              <ValidationError
                                errorMessage={formState.errors.password.message}
                              />
                            </span>
                          )}
                        </Form.Group>
                      </div>
                    </Col>

                    <Col className="label">
                      <div>
                        <Form.Group
                          className="mb-1"
                          controlId="formBasicConfirmPassword"
                        >
                          <Form.Label className="fw-bold">
                            Confirm Password
                          </Form.Label>
                          <Form.Control
                            {...register("confirmPassword", {
                              required: {
                                value: true,
                                message:
                                  "You must confirm password before moving forward.",
                              },
                              validate: (val: string) => {
                                if (watch("password") !== val) {
                                  return "Your passwords do no match"
                                }
                              },
                            })}
                            placeholder="Re-Enter Your Password"
                            className="sign-up-input"
                            type="password"
                            name="confirmPassword"
                            required
                            onChange={handleSignUpInputChange}
                          />
                          {formState.errors.confirmPassword && (
                            <span className="error_message">
                              <ValidationError
                                errorMessage={
                                  formState.errors.confirmPassword.message
                                }
                              />
                            </span>
                          )}
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div>
                  {!isLinkValid && (
                    <Form.Group className="mb-1" controlId="formBasicLastName">
                      <div className="d-flex justify-content-start mt-3  form-check ">
                        <span className="ms-3">
                          <Form.Control
                            {...register("isChecked", {
                              required: {
                                value: true,
                                message:
                                  "Please accept the terms and conditions",
                                // "You must specify a Last Name before moving forward.",
                              },
                            })}
                            className="form-check-input justify-content-center"
                            type="checkbox"
                            name="isChecked"
                            value=""
                            onChange={handleSignUpInputChange}
                            id="flexCheckDefault"
                          />
                        </span>
                        <span>
                          <p className="form-check-label ms-3">
                            I agree to all the{" "}
                            <a href="https://feeschedulepro.com/terms-and-conditions/">
                              terms and conditions
                            </a>{" "}
                            and{" "}
                            <a href="https://feeschedulepro.com/privacy-policy/">
                              privacy policy
                            </a>
                          </p>
                        </span>
                      </div>
                      {formState.errors.isChecked && (
                        <span className="error_message">
                          <ValidationError
                            errorMessage={formState.errors.isChecked.message}
                          />
                        </span>
                      )}
                    </Form.Group>
                  )}
                </div>
                {resetLink || isLinkValid ? (
                  <div className="mt-1">
                    <CustomClickableButton
                      buttonContent="Reset password"
                      handleOnClick={signUpHandler(handleResetClick, onError)}
                      disable={false}
                      classNaming="btn-round"
                    />
                  </div>
                ) : (
                  <div className="sign_up_button mt-2">
                    <CustomClickableButton
                      buttonContent="SIGN UP"
                      handleOnClick={signUpHandler(handleSignUpClick, onError)}
                      disable={false}
                      classNaming="btn-round"
                    />
                  </div>
                )}
              </Form>
            </div>
          </div>
          <div>
            <p className="d-flex justify-content-center mt-2">
              Already have an account?
              <a
                className="ps-2  fw-bold sign-up-link "
                onClick={handleSignInClick}
              >
                Sign In
              </a>
            </p>
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default styled(SignUp)`
  .error_message {
    display: flex;
    margin-top: 0;
    margin-bottom: 0;
  }
  .signup-container {
    background-color: #253551;
    background-image: radial-gradient(
      circle at top right,
      #441d90 0%,
      #253551 100%
    );
    padding: 20px;
    width: 75%;
    height: 80vh;
    border-radius: 20px;
    justify-content: center;
    align-content: center;
  }
  .sign-up-input {
    border-radius: 20px;
    position: relative;
    width: 25%;
  }
  @media only screen and (max-width: 575px) {
    .bottom_content {
      font-size: 0.75rem;
    }
  }
  @media only screen and (max-width: 900px) {
    .sign-up-input {
      border-radius: 20px;
      width: 100%;
      left: 0%;
    }
    .signup_form {
      text-align: left !important;
    }
    .sign_up_button {
      display: flex !important;
      align-items: center;
      flex-direction: column;
    }
  }
  .sign-up-wrapper {
    min-height: 100vh;
  }
  @media only screen and (min-width: 900px) {
    .sign-up-input {
      border-radius: 10px;
      padding: 10px;
      pading-left: 3px;
      width: 90%;
      left: 0%;
    }
    .form-container {
      max-width: 60%;
    }
    .error_message {
      position: relative;
      display: flex;
      width: 30%;
      left: 37%;
    }
  }

  .login-image {
    margin-left: 270px;
  }

  .sign-up-link {
    text-underline-offset: 3px;
  }
  @media only screen and (max-height: 800px) {
    .signup-container {
      height: 100vh;
    }
  }
  .label {
    text-align: left;
  }
`
