import React, { useEffect, useState } from "react"
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap"
import styled from "styled-components"
import authentication from "../core/authentication"
import CustomClickableButton from "./custom-components/custom-clickable-button"
import CustomUnclickableButton from "./custom-components/custom-unclickable-button"
import axios from "axios"
import { appConfig } from "../core"
import PopupModal from "./popup-modal"
import { useLazyQuery } from "@apollo/client"
import { GET_USER_BY_ID } from "../graphql/graphql-queries"
import { useForm } from "react-hook-form"
import config from "../core/config"
import ValidationError from "./validation-error"
import { useNavigate } from "react-router-dom"

interface CheckoutProps {
  className?: string
}
const Checkout: React.FC<CheckoutProps> = ({ className }) => {
  let navigate = useNavigate()
  const [selectedCredits, setSelectedCredits] = useState(0)
  const [showPayPopup, setShowPayPopup] = useState(false)
  let user = authentication?.getUserData()
  const [userAddress, setUserAddress] = useState(undefined)
  const [userDetails, setUserDetails] = useState()
  const [successMessage, setSuccessMessage] = useState("")
  const params = new URLSearchParams(window.location.search)
  const successParam = params.get("success")
  const idParam = params.get("id")
  const [errorMessage, setErrorMessage] = useState("")
  const canceledParam = params.get("canceled")

  useEffect(() => {
    if (successParam) {
      setSuccessMessage("Credit Successfully! added To user account")
    } else if (canceledParam) {
      setSuccessMessage("Some error encountered! please try again ")
    }
  }, [])

  const [processing, setProcessing] = useState(false)
  const [transactComplete, setTransactComplete] = useState(false)
  const [checkBillingDetails] = useLazyQuery(GET_USER_BY_ID, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setUserAddress(data?.getUserById?.userAddress)
      setUserDetails(data?.getUserById)
    },
  })
  useEffect(() => {
    checkBillingDetails({
      variables: {
        userId: user.id,
      },
    })
  }, [showPayPopup])

  const handleBuyButton = () => {
    userAddress ? buyCreditsFromStripe() : setShowPayPopup(true)
  }
  const handleCancelClick = () => {
    navigate("/")
  }
  const handleDownload = async () => {
    const token = authentication.getAccessToken()
    await axios({
      url: `${appConfig.apiUrl}/rest/download-invoice`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/pdf",
        Authorization: `Bearer ${token}`,
      },
      data: { userId: user.id },
    }).then((res) => {
      const blobData = res.data.content.data
      const byteArray = new Uint8Array(blobData)
      const blob = new Blob([byteArray], { type: "application/pdf" })
      const blobUrl = URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = blobUrl
      link.download = "Invoice.pdf"
      link.click()
    })
  }
  const buyCreditsFromStripe = async () => {
    try {
      setProcessing(true)
      let user = authentication.getUserData()
      const token = authentication.getAccessToken()
      let body = {
        productName: `${selectedCredits} Credits`,
        unitAmount:
          selectedCredits > 15
            ? config.creditsValueMoreThan15
            : selectedCredits > 9 && selectedCredits <= 15
            ? config.creditsValueLess10More15
            : config.creditsValueLessThan10,
        quantity: selectedCredits,
        userId: user.id,
      }
      let res: any = await axios({
        method: "POST",
        url: `${appConfig.apiUrl}/rest/create-checkout-session`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify(body),
      })
      const sessionUrl = await res.data
      if (sessionUrl) {
        window.location.href = sessionUrl
        setProcessing(true)
      }
      setTransactComplete(true)
      return true
    } catch (error) {
      setTransactComplete(false)
      setProcessing(false)
      console.error("Error: ", error)
    }
  }
  const handleInputChange = (e: any) => {
    const inputValue = e.target.value
    const isPositiveInteger = /^[1-9]\d*$/.test(inputValue)
    if (isPositiveInteger || inputValue > 0) {
      setSelectedCredits(inputValue)
      setErrorMessage("")
    } else {
      setSelectedCredits(0)
      setErrorMessage("Please enter a positive value of credits.")
    }
    // setSelectedCredits(e.target.value)
  }
  const { register, handleSubmit: handlePopupClick, formState } = useForm()
  const tdStyle = {
    border: "3px solid #d205c6",
    width: "300px",
  }
  return (
    <div className={className}>
      <Container>
        <Row className="d-flex justify-content-center">
          <p className="fs-5 fw-bold text-primary">Buy Credits</p>
          <p className="text-muted">To download the results buy credits</p>
          <Col sm={3}>
            <Form>
              <Form.Group controlId="formBasicCredits">
                <Form.Label>Credits</Form.Label>
                <Form.Control
                  {...register("CreditValue", {
                    required: {
                      value: true,
                      message:
                        "You must specify some credits before moving forward.",
                    },
                  })}
                  type="number"
                  name="CreditValue"
                  className="text-capitalize"
                  onChange={handleInputChange}
                />
                {errorMessage !== "" && (
                  <ValidationError errorMessage={errorMessage} />
                )}
              </Form.Group>
              <p className="mt-3">
                Selected option:{" "}
                {selectedCredits !== null ? `${selectedCredits} Credits` : ""}
              </p>
            </Form>
            <p className="text-primary">{successMessage}</p>
            {!!successParam && !!idParam && (
              <CustomClickableButton
                variant="outline-primary rounded-pill fw-bold"
                className="outline-button"
                classNaming="btn btn-primary"
                handleOnClick={handleDownload}
              >
                Download Invoice
              </CustomClickableButton>
            )}
          </Col>
        </Row>

        <Row className="mt-4 d-flex d-flex justify-content-center">
          <Col sm={2}>
            {selectedCredits ? (
              <CustomClickableButton
                variant="outline-primary rounded-pill fw-bold"
                className="outline-button"
                disable={false}
                classNaming="btn btn-primary"
                handleOnClick={handleBuyButton}
              >
                {processing ? "Processing..." : "Buy Credits"}
              </CustomClickableButton>
            ) : (
              <div className="unclick">
                <CustomUnclickableButton
                  buttonContent="Add Credits"
                  disabled={true}
                ></CustomUnclickableButton>
              </div>
            )}
          </Col>
          <Col sm={2}>
            <Button
              variant="outline-primary rounded-pill fw-bold"
              className="outline-button"
              onClick={() => handleCancelClick()}
            >
              Cancel
            </Button>
          </Col>
        </Row>
        <div className="d-flex justify-content-center mt-4">
          <Table
            responsive
            bordered
            onContextMenu={(e) => e.preventDefault()}
            className="table-container"
          >
            <thead>
              <tr>
                <th style={tdStyle}>CREDITS</th>
                <th style={tdStyle}>AMOUNT ($ Per Credit)</th>
              </tr>
            </thead>
            <tbody>
              <tr key={0}>
                <td style={tdStyle}>1 - 9 Credits </td>
                <td style={tdStyle}>
                  ${appConfig.creditsValueLessThan10 / 100}
                </td>
              </tr>
              <tr key={1}>
                <td style={tdStyle}>10 - 15 Credits </td>
                <td style={tdStyle}>
                  ${appConfig.creditsValueLess10More15 / 100}
                </td>
              </tr>
              <tr key={2}>
                <td style={tdStyle}>More Than 15 Credits </td>
                <td style={tdStyle}>
                  ${appConfig.creditsValueMoreThan15 / 100}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        {showPayPopup && (
          <PopupModal
            setTransactComplete={setTransactComplete}
            selectedPlan={selectedCredits}
            setProcessing={setProcessing}
            user={userDetails}
            show={showPayPopup}
            onHide={() => setShowPayPopup(false)}
            setShowPayPopup={setShowPayPopup}
            size="lg"
          />
        )}
      </Container>
    </div>
  )
}

export default styled(Checkout)`
  .table-container {
    border: "2px solid red";
    text-align: center;
  }
`
