import React from "react"
import {
  Routes,
  Route,
  Outlet,
  BrowserRouter,
  Navigate,
} from "react-router-dom"
import "./App.css"
import { client } from "./ApolloClient/client"
import { ApolloProvider } from "@apollo/client"
import NavigationBar from "./components/navigation-bar"
import FilesList from "./components/files-list"
import PrivateRoutes from "./components/private-routes"
import ResultList from "./components/result-list"
import authentication from "./core/authentication"
import CustomErrorNotification from "./components/custom-components/custom-error-notification"
import SignIn from "./components/sign-in"
import SignUp from "./components/sign-up"
import Settings from "./components/setting-components/settings"
import Footer from "./components/footer"
import NotFound from "./components/not-found"
import Checkout from "./components/checkout"
import TestPayment from "./components/test-payment"

const NavbarLayout = () => (
  <>
    <NavigationBar />
    <Outlet />
  </>
)
function App() {
  let auth = authentication.getAccessToken()
  const params = new URLSearchParams(window.location.search)
  const inspect = params.get("inspect")
  if (process.env.NODE_ENV === "production" && !inspect) {
    document.addEventListener("contextmenu", (e) => e.preventDefault())

    const ctrlShiftKey = (e: KeyboardEvent, keyCode: string) => {
      return e.ctrlKey && e.shiftKey && e.key === keyCode
    }
    const commandOptionKey = (e: KeyboardEvent, keyCode: string) => {
      return e.metaKey && e.altKey && e.key === keyCode
    }

    document.onkeydown = (e) => {
      // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
      if (
        e.key === "F12" ||
        ctrlShiftKey(e, "I") ||
        ctrlShiftKey(e, "J") ||
        ctrlShiftKey(e, "C") ||
        commandOptionKey(e, "I") ||
        commandOptionKey(e, "J") ||
        commandOptionKey(e, "C") ||
        (e.ctrlKey && e.key === "U")
      )
        return false
    }
  }
  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <div className="App">
          <Routes>
            <Route
              path="/login"
              element={auth ? <Navigate to="/" /> : <SignIn />}
            />
            <Route path="/signup" element={<SignUp />} />
            <Route
              path="/session-expired"
              element={<CustomErrorNotification />}
            />
            <Route path="/reset-password" element={<SignUp />} />
            <Route element={<NavbarLayout />}>
              <Route element={<PrivateRoutes />}>
                <Route
                  path="/"
                  element={
                    authentication.isSuperAdmin() ||
                    authentication.isClient() ? (
                      <FilesList />
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
                <Route
                  path="/result/:id"
                  element={
                    authentication.isSuperAdmin() ||
                    authentication.isClient() ? (
                      <ResultList />
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
                <Route
                  path="/settings"
                  element={
                    authentication.isSuperAdmin() ||
                    authentication.isClient() ? (
                      <Settings />
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
                <Route
                  path="/checkout"
                  element={
                    authentication.isSuperAdmin() ||
                    authentication.isClient() ? (
                      <Checkout />
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
                <Route
                  path="/test-payment"
                  element={
                    authentication.isSuperAdmin() ? (
                      <TestPayment />
                    ) : (
                      <Navigate to="/login" />
                    )
                  }
                />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Route>
          </Routes>
          {authentication.isSuperAdmin() || authentication.isClient() ? (
            <div className="footer ">
              <Footer />
            </div>
          ) : (
            <></>
          )}
        </div>
      </BrowserRouter>
    </ApolloProvider>
  )
}

export default App
