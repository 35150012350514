import { Toast, ToastContainer } from 'react-bootstrap'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
} from '@heroicons/react/solid'
import styled from 'styled-components'

export interface CustomAlertProps extends React.ComponentProps<any> {
  className?: string
  alertType?: string
  alertMessage: string
  handleOnClose: () => void
}

const CustomAlert: React.FC<CustomAlertProps> = ({
  className,
  alertType,
  alertMessage,
  handleOnClose,
}) => {
  return (
    <div className={`${className} d-flex justify-content-center`}>
      <ToastContainer className={` w-50 mt-3 p-4 position-fixed `}>
        <Toast
          className={`w-auto fw-bold d-flex justify-content-center ${
            alertType === "success"
              ? "text-primary"
              : alertType === "error"
              ? "text-error-dark"
              : "text-warning-dark"
          }`}
          bg={
            alertType === "success"
              ? "success-light"
              : alertType === "error"
              ? "error-light"
              : "warning-light"
          }
          onClose={handleOnClose}
          delay={4000}
          autohide
          animation
        >
          <div className="d-flex align-items-center">
            {alertType === "success" ? (
              <CheckCircleIcon className="check-icon icon-size hero-icon-primary" />
            ) : alertType === "error" ? (
              <ExclamationCircleIcon className="exclamation-circle-icon icon-size hero-icon-primary" />
            ) : (
              <ExclamationIcon className="exclamation-icon icon-size hero-icon-primary" />
            )}
            <Toast.Body className="toast-content">{alertMessage}</Toast.Body>
          </div>
        </Toast>
      </ToastContainer>
    </div>
  );
}

export default styled(CustomAlert)`
  .check-icon {
    color: #0c5a55;
  }

  .exclamation-circle-icon {
    color: #be0202;
  }

  .exclamation-icon {
    color: #e97e01;
  }

  .toast {
    font-size: 15px !important;
  }
`;
