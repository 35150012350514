import { useState } from "react";
import { Container, Row, Tab, Tabs } from "react-bootstrap";
import { useQuery } from "@apollo/client";
import authentication from "../../core/authentication";
import UserProfileSettings from "./user-profile-settings";
import { GET_USER_BY_ID } from "../../graphql/graphql-queries";
import CustomAlert from "../custom-components/custom-alert";
import Transactions from "../transactions";
import styled from "styled-components";

export interface SettingsProps extends React.ComponentProps<any> {
  className?: string;
}

const Settings: React.FC<SettingsProps> = ({ className }) => {
  const [user, setUser] = useState(null);

  const [errorMessage, setErrorMessage] = useState("");

  const [successMessage, setSuccessMessage] = useState("");

  const [cancelClicked, setCancelClicked] = useState(false);

  let loggedInUserData = authentication.getUserData();

  useQuery(GET_USER_BY_ID, {
    variables: {
      userId: loggedInUserData.id,
    },
    onCompleted: (data) => {
      setUser(data.getUserById);
    },
  });

  return (
    <div className={className}>
      <Container className="w-75 bg-white">
        {successMessage && (
          <Row className="flex-d justify-content-end pb-3 me-5">
            <CustomAlert
              handleOnClose={() => setSuccessMessage("")}
              alertType="success"
              alertMessage={successMessage}
            />
          </Row>
        )}
        {errorMessage && (
          <Row className="flex-d justify-content-end pb-3 me-5">
            <CustomAlert
              handleOnClose={() => setErrorMessage("")}
              alertType="error"
              alertMessage={errorMessage}
            />
          </Row>
        )}
        <h4 className="p-4 fw-bold text-primary">User Account Settings</h4>
        <Tabs
          defaultActiveKey="userProfileSettings"
          id="uncontrolled-tab-example"
          className="mb-3 d-flex justify-content-center tabs"
        >
          <Tab eventKey="userProfileSettings" title="User Profile">
            <UserProfileSettings
              user={user}
              setSuccessMessage={setSuccessMessage}
              setErrorMessage={setErrorMessage}
              cancelClicked={cancelClicked}
              setCancelClicked={setCancelClicked}
            />
          </Tab>
          <Tab eventKey="transactionHistory" title="Transaction History">
            <Transactions
              user={user}
              setSuccessMessage={setSuccessMessage}
              setErrorMessage={setErrorMessage}
              cancelClicked={cancelClicked}
              setCancelClicked={setCancelClicked}
            />
          </Tab>
        </Tabs>
      </Container>
    </div>
  );
};

export default styled(Settings)`
  .nav-tabs .nav-link {
    color: #000000;
  }
  .nav-tabs .nav-link.active {
    color: #d205c6 !important;
    font-weight: bolder;
  }
`;
