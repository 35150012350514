import { useEffect, useState } from "react"
import { useLazyQuery, useQuery } from "@apollo/client"
import { Container, Row, Col, Button, Spinner } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import moment from "moment"
import styled from "styled-components"
import {
  CHECK_USER_CREDITS_BY_USER_ID,
  GET_DOCUMENT_BY_ID,
  GET_USER_CREDIT,
  LIST_FILE_UPLOADS_BY_USER_ID,
  LIST_PENDING_FILE_BY_USER_ID,
} from "../graphql/graphql-queries"
import SpinnerLoading from "./spinner-loading"
import ErrorFetchingData from "./error-fetching-data"
import UploadFileToS3 from "./upload-file-to-s3"
import {
  handleViewDocument,
  reloadPageAfter30sDelay,
} from "../helper-functions"
import Pagination from "./pagination"
import config from "../core/config"
import authentication from "../core/authentication"
import CustomAlert from "./custom-components/custom-alert"
import FeedBackPopupModal from "./feedback-popup"
import { appConfig } from "../core"
import axios from "axios"
import saveAs from "file-saver"
import DownloadPopup from "./download-popup"
export interface FilesListProps extends React.ComponentProps<any> {
  className?: string
}
const FilesList: React.FC<FilesListProps> = ({ className }) => {
  let navigate = useNavigate()

  const [files, setFiles] = useState([])
  const [pageNumber, setPageNumber] = useState(1)
  const [pendingFileList, setPendingFileList] = useState([])
  const [count, setCount] = useState(0)
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false)
  const [fileIdForFeedback, setFileIdForFeedback] = useState("")
  const [feedbackStatus, setFeedbackStatus] = useState(false)
  const [openFile, setOpenFile] = useState(false)
  const [feedbackMessage, setFeedbackMessage] = useState("")
  const [isDownloaded, setIsDownloaded] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [validCredit, setValidCredit] = useState(false)
  const [userCredit, setUserCredit] = useState(0)
  const [isSuperfile, setIsSuperfile] = useState(false)
  const [fileUploadName, setFileUploadName] = useState("")
  const [documentId, setDocumentId] = useState("")
  const [fileUploadStatus, setFileUploadStatus] = useState(false)
  const [processing, setProcessing] = useState(false)

  const fileListArray = files
  const offset = 0
  const dataPerPage = config.itemPerPage
  const user = authentication?.getUserData()
  let paginationDataForFileList = {
    userId: user?.id,
    offset: pageNumber === 1 ? offset : (pageNumber - 1) * 25,
    limit: dataPerPage,
  }
  const [
    getListOfFileUploads,
    { loading: getListOfFileUploadsLoading, error: getListOfFileUploadsError },
  ] = useLazyQuery(LIST_FILE_UPLOADS_BY_USER_ID, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setFiles(data.listFileUploadsByUserId.fileUploads)
      // setFileIdForFeedback(data.listFileUploadsByUserId.fileUploads[0].id)
      setCount(data.listFileUploadsByUserId.count)
      setFileUploadStatus(false)
    },
  })
  useQuery(GET_USER_CREDIT, {
    variables: {
      userId: user.id,
    },
    onCompleted: (data) => {
      if (data?.getUserCredit?.credits) {
        setUserCredit(data?.getUserCredit?.credits)
      }
    },
  })
  const { refetch } = useQuery(LIST_PENDING_FILE_BY_USER_ID, {
    variables: {
      userId: user.id,
    },
    onCompleted: (data) => {
      setPendingFileList(data?.listPendingFilesByUserId)
    },
  })
  useEffect(() => {
    refetch()
  }, [])
  if (pendingFileList.length > 0) {
    reloadPageAfter30sDelay()
  }
  useEffect(() => {
    if (fileUploadStatus) {
      reloadPageAfter30sDelay()
    }
  }, [fileUploadStatus])
  useEffect(() => {
    getListOfFileUploads({
      variables: {
        data: paginationDataForFileList,
      },
    })
  }, [pageNumber])
  const perPage = Math.ceil(count / dataPerPage)
  const [checkUserCredits] = useLazyQuery(CHECK_USER_CREDITS_BY_USER_ID, {
    onCompleted: (data) => {
      if (user.role === "SUPER ADMIN" || data?.checkCreditOfUser?.credits > 0) {
        setValidCredit(true)
      } else {
        navigate(`/checkout`)
      }
    },
  })
  const [getDocumentById] = useLazyQuery(GET_DOCUMENT_BY_ID, {
    onCompleted: (data) => {
      setIsSuperfile(data?.getDocumentById?.isSuperFile)
      if (userCredit == 0 && user.role !== "SUPER ADMIN") {
        navigate(`/checkout`)
      }
      if (data?.getDocumentById?.isDownloaded !== true) setShowPopup(true)
    },
  })
  const getCsvFile = async () => {
    const token = authentication.getAccessToken()
    let operation = "DEBIT"
    let UseCreditInput = {
      userId: user?.id,
      operation: operation,
      docId: documentId,
    }
    await axios({
      url: `${appConfig.apiUrl}/rest/get-csv-file`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: { documentId: documentId, data: UseCreditInput },
      responseType: "arraybuffer",
    })
      .then((response) => {
        if (isSuperfile) {
          const blob = new Blob([response.data], {
            type: "application/octet-stream",
          })
          saveAs(blob, `${fileUploadName}_converted.zip`)
        } else {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
          saveAs(blob, `${fileUploadName}_converted.csv`)
        }

        setProcessing(false)
      })
      .then(() => {
        setTimeout(() => {
          window.location.reload()
        }, 500)
      })
      .catch((error) => {
        setProcessing(false)
        console.error("Error downloading Excel file:", error)
      })
  }
  const handleViewDocumentClick = (documentId: string) => {
    navigate(`/result/${documentId}`)
  }
  const checkCreditAndGetCSV = async (
    docId: any,
    fileName: any,
    isDownloaded: any
  ) => {
    setFileUploadName(fileName)
    setDocumentId(docId)
    setProcessing(true)
    setIsDownloaded(isDownloaded)
    if (!isDownloaded) {
      await getDocumentById({
        variables: {
          documentId: docId,
        },
      })
    }
  }

  const checkCreditForUser = async () => {
    if (isDownloaded)
      await checkUserCredits({ variables: { userId: user?.id } })
  }
  useEffect(() => {
    if (validCredit) {
      getCsvFile()
    }
  }, [validCredit])

  useEffect(() => {
    if (isDownloaded) getCsvFile()
  }, [isDownloaded])
  if (getListOfFileUploadsLoading) return <SpinnerLoading />
  if (getListOfFileUploadsError) return <ErrorFetchingData />
  return (
    <div className={className}>
      {feedbackStatus && (
        <Row className="flex-d justify-content-center pb-3 me-5">
          <CustomAlert
            handleOnClose={() => {
              setFeedbackStatus(false)
              setFeedbackMessage("")
            }}
            alertType="success"
            alertMessage={feedbackMessage}
          />
        </Row>
      )}
      {openFile && (
        <Row className="flex-d justify-content-center pb-3 me-5">
          <CustomAlert
            handleOnClose={() => {
              setOpenFile(false)
            }}
            alertType="success"
            alertMessage={"Please wait opening the file..."}
          />
        </Row>
      )}
      <Container className="  bg-white text-color">
        <div>
          <Row className="w-50 m-auto   p-3 d-flex justify-content-center align-items-center mb-2">
            <Col sm={10}>
              <UploadFileToS3
                fileUploadStatus={fileUploadStatus}
                setFileUploadStatus={setFileUploadStatus}
              />
            </Col>
          </Row>
        </div>
        <Row className="fw-bold py-3 justify-content-center">
          <Col className="text-start" sm={3}>
            File Name
          </Col>
          <Col className="text-start" sm={3}>
            Status
          </Col>
          <Col className="text-start" sm={2}>
            Created at
          </Col>
          <Col className="justify-content-center " sm={4}>
            Action
          </Col>
        </Row>
        {!!files?.length ? (
          <>
            {fileListArray.map((file: any, index: number) => (
              <Row
                className="border-top d-flex align-items-center py-2 justify-content-center"
                key={file.id}
              >
                <Col className="text-start" sm={3}>
                  <div>
                    {file.status === "COMPLETED" ? (
                      <a
                        onClick={() => {
                          setOpenFile(true)
                          handleViewDocument(file?.path)
                        }}
                        className="link add-cursor"
                      >
                        {file?.originalName}
                      </a>
                    ) : (
                      file?.originalName
                    )}
                  </div>
                </Col>
                <Col className="text-start" sm={3}>
                  <div>{file?.status}</div>
                </Col>
                <Col className="text-start" sm={2}>
                  <div>{moment(file?.createdAt).format("MM/DD/YYYY")}</div>
                </Col>
                <Col sm={1}>
                  <div>
                    {file?.status === "FAILED" ? (
                      <span className="fw-bold">Try Again</span>
                    ) : file?.status === "PENDING" ? (
                      <span className="fw-bold">Pending...</span>
                    ) : (
                      <Button
                        className="btn btn-sm btn-success rounded-pill fw-bold p-2 ps-3 pe-3"
                        onClick={() => {
                          handleViewDocumentClick(file?.documentId)
                        }}
                      >
                        View Result
                      </Button>
                    )}
                  </div>
                </Col>
                {file?.status == "COMPLETED" ? (
                  <Col sm={2}>
                    <div>
                      <Button
                        className="btn btn-sm rounded-pill fw-bold p-2 ps-3 pe-3 download-button "
                        onClick={() => {
                          checkCreditAndGetCSV(
                            file.documentId,
                            file.originalName,
                            file.document.isDownloaded
                          )
                        }}
                      >
                        {file.document.isDownloaded
                          ? "Download Result"
                          : !processing
                          ? "Purchase Result"
                          : "Processing.."}
                      </Button>
                    </div>
                  </Col>
                ) : (
                  <Col sm={1}></Col>
                )}
                {file?.status == "COMPLETED" ? (
                  <Col sm={1}>
                    <div>
                      <Button
                        className="btn btn-sm  rounded-pill fw-bold p-2 ps-3 pe-3 feedback-button"
                        onClick={() => {
                          setShowFeedbackPopup(true)
                          setFileIdForFeedback(file.id)
                        }}
                      >
                        FeedBack
                      </Button>
                    </div>
                  </Col>
                ) : (
                  <Col sm={2}></Col>
                )}
                {/* <Col sm={1}></Col> */}
              </Row>
            ))}
            {showFeedbackPopup && (
              <FeedBackPopupModal
                show={showFeedbackPopup}
                user={user}
                fileuploadId={fileIdForFeedback}
                onHide={() => setShowFeedbackPopup(false)}
              />
            )}

            {showPopup && (
              <DownloadPopup
                processing={processing}
                fileUploadName={fileUploadName}
                setProcessing={setProcessing}
                show={showPopup}
                documentId={documentId}
                isSuperFile={isSuperfile}
                onHide={() => setShowPopup(false)}
                setShowPopup={setShowPopup}
              />
            )}
          </>
        ) : (
          <Row className="border-top">
            <p className="text-center m-3">No Files have been added yet.</p>
          </Row>
        )}
        {files?.length > 0 && (
          <Pagination
            currentPage={pageNumber}
            setCurrentPage={setPageNumber}
            perPage={perPage}
          />
        )}
      </Container>
    </div>
  )
}

export default styled(FilesList)`
  .dots-vertical-icon {
    height: 25px;
    color: #13948b;
    cursor: pointer;
  }

  .span-cursor {
    cursor: pointer;
  }
  .feedback-button {
    background: #9263e3;
    border: 1px solid #9263e3;
  }
  .download-button {
    background: #2e5dad;
    border: 1px solid #2e5dad;
  }
  .dropdown-toggle::after {
    content: none;
  }
  .text-color {
    color: #253551;
  }
`
