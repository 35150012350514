import { gql } from "@apollo/client"

export const LIST_FILE_UPLOADS_BY_USER_ID = gql`
  query listFileUploadsByUserId($data: paginationDataForFileList!) {
    listFileUploadsByUserId(data: $data) {
      fileUploads {
        id
        createdAt
        updatedAt
        originalName
        assignedName
        status
        path
        document {
          name
          totalPages
          isDownloaded
          isSuperFile
        }
        userId
        documentId
      }
      count
    }
  }
`

export const LIST_PENDING_FILE_BY_USER_ID = gql`
  query listPendingFilesByUserId($userId: String!) {
    listPendingFilesByUserId(userId: $userId) {
      id
      originalName
      assignedName
      status
      path
      documentId
      userId
    }
  }
`

export const GET_USER_CREDIT = gql`
  query getUserCredit($userId: String!) {
    getUserCredit(userId: $userId) {
      id
      credits
    }
  }
`
export const GET_FILE_UPLOAD_BY_DOC_ID = gql`
  query getfileUploadByDocId($documentId: String!) {
    getfileUploadByDocId(documentId: $documentId) {
      id
      path
      originalName
      assignedName
      status
      userId
      documentId
      createdAt
    }
  }
`
export const GET_DOCUMENT_BY_ID = gql`
  query getDocumentById($documentId: String!) {
    getDocumentById(documentId: $documentId) {
      id
      isDownloaded
      isSuperFile
      name
      totalPages
      createdAt
    }
  }
`
export const GET_FILE_UPLOAD_BY_ID = gql`
  query getfileUpload($fileUploadId: String!) {
    getfileUpload(fileUploadId: $fileUploadId) {
      id
      path
      originalName
      assignedName
      status
      userId
      documentId
      createdAt
    }
  }
`
export const SEND_FEEDBACK_TO_ADMIN = gql`
  mutation fileUploadIssue($data: feedBackInput!) {
    fileUploadIssue(data: $data) {
      userId
    }
  }
`

export const LIST_PROCEDURE_ROWS = gql`
  query listProcedureRows($documentId: String!) {
    listProcedureRows(documentId: $documentId) {
      id
      code
      procedureName
      amount
      documentId
      isSuperfile
      groupNo
    }
  }
`

export const LIST_PROCEDURE_ROWS_PAGINATED = gql`
  query listProcedureRowsPaginated($data: PaginationProcedureRowsInput!) {
    listProcedureRowsPaginated(data: $data) {
      procedureRows {
        id
        code
        procedureName
        amount
        documentId
        isSuperfile
        groupNo
      }
      count
      isSuperFile
    }
  }
`

export const GET_USER_BY_ID = gql`
  query getUserById($userId: String!) {
    getUserById(userId: $userId) {
      id
      email
      avatarURL
      role
      enabled
      creditId
      firstName
      lastName
      fullName
      password
      userAddress
    }
  }
`

export const USE_CREDIT_TO_DOWNLOAD = gql`
  mutation useCredit($data: UseCreditInput!) {
    useCredit(data: $data) {
      id
      userId
      credits
      purchasedCredits
      creditTransactions {
        id
        createdAt
        userId
        transactionId
        preTransactCredits
        postTransactCredits
        transactCredits
        operation
        creditId
      }
    }
  }
`
export const ADD_CREDIT_TO_USER = gql`
  mutation addCreditToUser($data: AddCreditInput!) {
    addCreditToUser(data: $data) {
      id
      credits
      purchasedCredits
      creditTransactions {
        id
        transactionId
        preTransactCredits
        postTransactCredits
        transactCredits
        operation
      }
    }
  }
`
export const GET_CREDIT_OF_USER = gql`
  query getCreditOfUser($data: paginationData!) {
    getCreditOfUser(data: $data) {
      creditTransactions {
        id
        documentId
        transactionId
        Document {
          name
          fileUploadId
        }
        preTransactCredits
        postTransactCredits
        transactCredits
        operation
        name
        createdAt
        creditId
      }
      count
    }
  }
`

export const CHECK_USER_CREDITS_BY_USER_ID = gql`
  query checkCreditOfUser($userId: String!) {
    checkCreditOfUser(userId: $userId) {
      id
      userId
      credits
      purchasedCredits
    }
  }
`
export const RECOVER_PASSWORD = gql`
  mutation recoverPassword($data: RecoverPasswordEmailInput!) {
    recoverPassword(data: $data) {
      id
      createdAt
      updatedAt
      email
      avatarURL
      role
      enabled
      firstName
      lastName
      phoneNumber
      password
      fullName
      isSuperAdmin
      isClient
    }
  }
`

export const SIGN_UP_USER = gql`
  mutation signUpUser($data: SignUpInput!) {
    signUpUser(data: $data) {
      id
      email
      avatarURL
      role
      enabled
      firstName
      lastName
      phoneNumber
      fullName
      isSuperAdmin
      isClient
    }
  }
`

export const RESET_PASSWORD_USER = gql`
  mutation resetPassword($data: SignUpInput!) {
    resetPassword(data: $data) {
      id
      email
      avatarURL
      role
      enabled
      firstName
      lastName
      phoneNumber
      fullName
      isSuperAdmin
      isClient
    }
  }
`

export const UPDATE_USER_DETAILS = gql`
  mutation updateUserDetails($userId: String!, $data: UpdateUserInput!) {
    updateUserDetails(userId: $userId, data: $data) {
      id
      email
      avatarURL
      role
      enabled
      firstName
      lastName
      password
    }
  }
`

export const UPDATE_USER_PASSWORD = gql`
  mutation updateUserPassword(
    $userId: String!
    $data: UpdateUserPasswordInput!
  ) {
    updateUserPassword(userId: $userId, data: $data) {
      id
      password
    }
  }
`

export const GENERATE_INVOICE_FOR_USER = gql`
  mutation generateInvoice($data: generateInvoiceInput!) {
    generateInvoice(data: $data) {
      id
      userId
      invoiceLines {
        id
        invoiceId
        textValue
        localText
      }
    }
  }
`

export const ADD_USER_DETAILS = gql`
  mutation addUserBillingDetails($data: UserBillingDetailsInput!) {
    addUserBillingDetails(data: $data) {
      id
    }
  }
`
export const GET_INVITATION_BY_TOKEN = gql`
  query getInvitationByToken($token: String!) {
    getInvitationByToken(token: $token) {
      id
      createdAt
      updatedAt
      sentDate
      token
      invitationType
      sesUniqueId
      expired
      userId
      user {
        id
        createdAt
        updatedAt
        email
        password
        avatarURL
        role
        enabled
        firstName
        lastName
        phoneNumber
      }
      emailSenderId
      emailSender {
        id
        createdAt
        updatedAt
        email
        avatarURL
        role
        enabled
        firstName
        lastName
        phoneNumber
      }
    }
  }
`
