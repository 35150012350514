import React, { ChangeEvent, useState } from "react"
import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import ValidationError from "./validation-error"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import { ADD_USER_DETAILS } from "../graphql/graphql-queries"
import { useMutation } from "@apollo/client"
import authentication from "../core/authentication"
import axios from "axios"
import { appConfig } from "../core"
import { useNavigate } from "react-router-dom"
import config from "../core/config"

export interface PopupModalProps extends React.ComponentProps<any> {
  className: string
  user: any
  isedit?: boolean
  show: boolean
  onHide: () => void
  selectedPlan: any
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>
  setShowPayPopup: React.Dispatch<React.SetStateAction<boolean>>
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>
  setTransactComplete: React.Dispatch<React.SetStateAction<boolean>>
  setsuccess?: React.Dispatch<React.SetStateAction<any>>
  setsuccessmessage?: React.Dispatch<React.SetStateAction<any>>
  seterrormessage?: React.Dispatch<React.SetStateAction<any>>
}
type InvoiceInput = {
  firstName: string
  lastName: string
  email: string
  address: string
  addressTwo: string
  city: string
  state: string
  zipCode: string
}
const PopupModal: React.FC<PopupModalProps> = (props: PopupModalProps) => {
  let navigate = useNavigate()
  const { register, handleSubmit: handlePopupClick, formState } = useForm()
  const user = authentication.getUserData()
  const onError = (errors: any, e: any) => console.log(errors, e)
  const [inoviceInput, setInoviceInput] = useState<InvoiceInput>({
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    addressTwo: "",
    city: "",
    state: "",
    zipCode: "",
  })
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setInoviceInput((prevInvoiceInput: any) => ({
      ...prevInvoiceInput,
      [name]: value,
    }))
  }
  let generateInvoiceInput = {
    id: user.id,
    address: inoviceInput.address,
    addressTwo: inoviceInput.addressTwo,
    city: inoviceInput.city,
    state: inoviceInput.state,
    zipCode: inoviceInput.zipCode,
  }
  const buyCreditsFromStripe = async () => {
    try {
      props.setProcessing(true)
      let user = authentication.getUserData()
      const token = authentication.getAccessToken()
      let body = {
        productName: `${props.selectedPlan} Credits`,
        unitAmount:
          props.selectedPlan > 15
            ? config.creditsValueMoreThan15
            : props.selectedPlan > 9 && props.selectedPlan <= 15
            ? config.creditsValueLess10More15
            : config.creditsValueLessThan10,
        quantity: props.selectedPlan,
        userId: user.id,
      }
      let res: any = await axios({
        method: "POST",
        url: `${appConfig.apiUrl}/rest/create-checkout-session`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: JSON.stringify(body),
      })
      const sessionUrl = await res.data
      if (sessionUrl) {
        props.setProcessing(true)
        window.location.href = sessionUrl
      }
      props.setTransactComplete(true)
      return true
    } catch (error) {
      props.setTransactComplete(false)
      props.setProcessing(false)
      console.error("Error: ", error)
    }
  }
  const [addUserDetails] = useMutation(ADD_USER_DETAILS, {
    onCompleted: (data) => {},
  })
  const handleAddDetails = async () => {
    try {
      await addUserDetails({
        variables: {
          data: generateInvoiceInput,
        },
      }).then(async () => {
        await buyCreditsFromStripe()
        props.setShowPayPopup(false)
      })
    } catch (error) {
      console.error("Error: ", error)
    }
  }

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={`${props.className}`}
    >
      <Modal.Header closeButton>
        <strong>Add Details for Billing</strong>
      </Modal.Header>
      <Modal.Body>
        <p className="text-muted">
          Please, Add billing details to move forward
        </p>
        <Form>
          <Form.Group controlId="formBasicEmail">
            <Row>
              <Col>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  {...register("firstName", {
                    required: {
                      value: true,
                      message:
                        "You must specify a First Name before moving forward.",
                    },
                  })}
                  type="text"
                  name="firstName"
                  defaultValue={props?.user?.firstName}
                  className="text-capitalize"
                  placeholder={props.firstname}
                  onChange={handleInputChange}
                  // autoComplete="off"
                />
                {formState.errors.firstName && (
                  <ValidationError
                    errorMessage={formState.errors.firstName.message}
                  />
                )}
              </Col>

              <Col>
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  {...register("lastName", {
                    required: {
                      value: true,
                      message:
                        "You must specify a Last Name before moving forward.",
                    },
                  })}
                  type="text"
                  name="lastName"
                  defaultValue={props?.user?.lastName}
                  className="text-capitalize"
                  placeholder={props.lastname}
                  onChange={handleInputChange}
                  // autoComplete="off"
                />
                {formState.errors.lastName && (
                  <ValidationError
                    errorMessage={formState.errors.lastName.message}
                  />
                )}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <Form.Label>Email</Form.Label>

                <Form.Control
                  name="email"
                  defaultValue={props?.user?.email}
                  placeholder="email@example.com"
                  onChange={handleInputChange}
                  // autoComplete="off"
                />
              </Col>
            </Row>
            <p className="mt-3">Billing Address</p>
            <Row className="">
              <Col>
                <Form.Label>Address</Form.Label>

                <Form.Control
                  {...register("address", {
                    required: {
                      value: true,
                      message:
                        "You must specify a Billing Address before moving forward.",
                    },
                  })}
                  name="address"
                  placeholder="Enter your address"
                  className="text-capitalize"
                  onChange={handleInputChange}
                  // autoComplete="off"
                />
                {formState.errors.address && (
                  <ValidationError
                    errorMessage={formState.errors.address.message}
                  />
                )}
              </Col>
              <Col>
                <Form.Label>Address Line 2</Form.Label>

                <Form.Control
                  // {...register("addressTwo", {
                  //   required: {
                  //     value: true,
                  //     message:
                  //       "You must specify Address line 2 before moving forward.",
                  //   },
                  // })}
                  name="addressTwo"
                  placeholder="Apartment, building, floor, etc."
                  className="text-capitalize"
                  onChange={handleInputChange}
                  // autoComplete="off"
                />
                {/* {formState.errors.addressTwo && (
                  <ValidationError
                    errorMessage={formState.errors.addressTwo.message}
                  />
                )} */}
              </Col>
            </Row>
            <Row className="mt-1">
              <Col>
                <Form.Label>City</Form.Label>

                <Form.Control
                  {...register("city", {
                    required: {
                      value: true,
                      message: "You must specify a city before moving forward.",
                    },
                  })}
                  name="city"
                  placeholder="City"
                  className="text-capitalize"
                  onChange={handleInputChange}
                  // autoComplete="off"
                />
                {formState.errors.city && (
                  <ValidationError
                    errorMessage={formState.errors.city.message}
                  />
                )}
              </Col>
              <Col>
                <Form.Label>State</Form.Label>

                <Form.Control
                  {...register("state", {
                    required: {
                      value: true,
                      message:
                        "You must specify a state before moving forward.",
                    },
                  })}
                  name="state"
                  placeholder="State"
                  className="text-capitalize"
                  onChange={handleInputChange}
                  // autoComplete="off"
                />
                {formState.errors.state && (
                  <ValidationError
                    errorMessage={formState.errors.state.message}
                  />
                )}
              </Col>
              <Col>
                <Form.Label>Zip Code</Form.Label>

                <Form.Control
                  {...register("zipCode", {
                    required: {
                      value: true,
                      message:
                        "You must specify a Zip Code before moving forward.",
                    },
                  })}
                  name="zipCode"
                  placeholder="Zip Code"
                  className="text-capitalize"
                  onChange={handleInputChange}
                  // autoComplete="off"
                />
                {formState.errors.zipCode && (
                  <ValidationError
                    errorMessage={formState.errors.zipCode.message}
                  />
                )}
              </Col>
            </Row>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "space-evenly" }}>
        <Row>
          <Col sm={6} className="text-start">
            <Button
              className="btn btn-warning cancelButton text-white rounded-pill"
              onClick={props.onHide}
            >
              Cancel
            </Button>
          </Col>
          <Col sm={6} className="text-end">
            <Button
              className="btn btn-primary submitButton rounded-pill text-white "
              onClick={handlePopupClick(handleAddDetails, onError)}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  )
}

export default styled(PopupModal)`
  .submitButton {
    width: 120px;
    height: 35px;
    margin-left: 50px;
    font-size: 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .cancelButton {
    background: #be0202;
    padding: 7px;
    width: 120px;
    border: none;
    margin-right: 50px;
  }
`
