import {
  Button,
  Col,
  Container,
  Dropdown,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import {
  GET_FILE_UPLOAD_BY_DOC_ID,
  LIST_PROCEDURE_ROWS_PAGINATED,
  CHECK_USER_CREDITS_BY_USER_ID,
  GET_DOCUMENT_BY_ID,
} from "../graphql/graphql-queries"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import SpinnerLoading from "./spinner-loading"
import ErrorFetchingData from "./error-fetching-data"
import authentication from "../core/authentication"
import PopupModal from "./popup-modal"
import ArrowLeftIcon from "@heroicons/react/solid/ArrowLeftIcon"
import styled from "styled-components"
import ResultsTable from "./results-table"
import config from "../core/config"
import SearchFilter from "./search-filter"
import Pagination from "./pagination"
import { useDebounce } from "./custom-hooks/use-debounce"
import { useParams } from "react-router-dom"
import axios from "axios"
import { appConfig } from "../core"
import CustomClickableButton from "./custom-components/custom-clickable-button"
import { saveAs } from "file-saver"
import DownloadPopup from "./download-popup"
interface ResultListProps {
  className?: string
}

const ResultList: React.FC<ResultListProps> = ({ className }) => {
  const [procedureRows, setProcedureRows] = useState([])
  const navigate = useNavigate()
  const docId = useParams()
  const documentId = docId?.id
  const [showPayPopup, setShowPayPopup] = useState(false)
  const [fileUploadId, setFileUploadId] = useState("")
  const [recordPerPage, setRecordPerPage] = useState(0)
  const [isDownloaded, setIsDownloaded] = useState(false)
  const [validBalance, setValidBalance] = useState(false)
  const [fileUploadName, setFileUploadName] = useState(null)
  const [searchTerm, setSearchTerm] = useState("")
  const debouncedSearchTerm = useDebounce(searchTerm, 1000)
  const [currentPage, setCurrentPage] = useState(1)
  const [showPopup, setShowPopup] = useState(false)
  const [dataPerPage] = useState(config.itemPerPage)
  const [count, setCount] = useState(1)
  const [isSuperFile, setIsSuperFile] = useState(false)
  const offset = 0
  let limit = recordPerPage > 0 ? recordPerPage : dataPerPage
  const perPage = Math.ceil(count / limit)

  let paginationDocumentsData = {
    documentId,
    offset: currentPage === 1 ? offset : (currentPage - 1) * limit,
    limit: limit,
    searchTerm: searchTerm.length >= 2 ? searchTerm : "",
  }
  const [processing, setProcessing] = useState(false)
  useEffect(() => {
    getProcedureRows({
      variables: { data: paginationDocumentsData },
    })
    getfileUploadByDocId({
      variables: {
        documentId: documentId,
      },
    })
    getDocumentById({
      variables: {
        documentId: documentId,
      },
    })
    refetch()
  }, [documentId, currentPage, recordPerPage])
  const [getfileUploadByDocId] = useLazyQuery(GET_FILE_UPLOAD_BY_DOC_ID, {
    onCompleted: (data) => {
      setFileUploadId(data?.getfileUploadByDocId?.id)
      setFileUploadName(data?.getfileUploadByDocId?.originalName)
    },
  })
  const [getDocumentById] = useLazyQuery(GET_DOCUMENT_BY_ID, {
    onCompleted: (data) => {
      setIsDownloaded(data?.getDocumentById?.isDownloaded)
    },
  })
  let user = authentication?.getUserData()

  const [
    getProcedureRows,
    { loading: getProcedureRowsLoading, error: getProcedureRowsError },
  ] = useLazyQuery(LIST_PROCEDURE_ROWS_PAGINATED, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      setProcedureRows(data?.listProcedureRowsPaginated?.procedureRows)
      setCount(data?.listProcedureRowsPaginated?.count)
      setIsSuperFile(data?.listProcedureRowsPaginated?.isSuperFile)
    },
  })
  const rows: any = []
  procedureRows?.map((procedureRow: any) => {
    const obj = {
      Code: procedureRow?.code,
      Amount: procedureRow?.amount,
      // Procedure_Name: procedureRow?.procedureName,
    }
    rows.push(obj)
  })

  const handleBackToFilesClick = () => {
    navigate("/")
  }

  const handleSearch = (e: any) => {
    setSearchTerm(e.target.value)
  }

  useEffect(() => {
    if (debouncedSearchTerm.length >= 2) {
      setCurrentPage(1)
      getProcedureRows({
        variables: { data: paginationDocumentsData },
      })
    }
    if (debouncedSearchTerm === "") {
      getProcedureRows({
        variables: { data: paginationDocumentsData },
      })
    }
  }, [debouncedSearchTerm])
  const { refetch } = useQuery(CHECK_USER_CREDITS_BY_USER_ID, {
    variables: { userId: user?.id },
    onCompleted: (data) => {
      if (data?.checkCreditOfUser?.credits > 0) {
        setValidBalance(true)
      }
    },
  })
  const [checkUserCredits] = useLazyQuery(CHECK_USER_CREDITS_BY_USER_ID, {
    onCompleted: (data) => {
      if (user.role === "SUPER ADMIN" || data?.checkCreditOfUser?.credits > 0) {
        getCsvFile()
      } else {
        navigate("/checkout")
      }
    },
  })

  const getCsvFile = async () => {
    const token = authentication.getAccessToken()
    let operation = "DEBIT"
    let UseCreditInput = {
      userId: user?.id,
      operation: operation,
      docId: documentId,
    }
    await axios({
      url: `${appConfig.apiUrl}/rest/get-csv-file`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: { documentId: documentId, data: UseCreditInput },
      responseType: "arraybuffer",
    })
      .then((response) => {
        if (isSuperFile) {
          const blob = new Blob([response.data], {
            type: "application/octet-stream",
          })
          saveAs(blob, `${fileUploadName}_converted.zip`)
        } else {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
          saveAs(blob, `${fileUploadName}_converted.csv`)
        }

        setProcessing(false)
      })
      .then(() => {
        setTimeout(() => {
          window.location.reload()
        }, 500)
      })
      .catch((error) => {
        setProcessing(false)
        console.error("Error downloading Excel file:", error)
      })
  }
  const handleDropdownClick = (e: any) => {
    setRecordPerPage(e)
  }
  const checkCreditAndGetCSV = async (isDownloaded: boolean) => {
    setProcessing(true)
    isDownloaded
      ? getCsvFile()
      : await checkUserCredits({ variables: { userId: user?.id } })
  }
  if (getProcedureRowsLoading) return <SpinnerLoading />
  if (getProcedureRowsError) return <ErrorFetchingData />
  return (
    <div className={className}>
      <Container className=" text-start bg-white text-color ">
        <Row className="fw-bold pb-5 pt-3">
          <Col sm={3}>
            <span
              onClick={handleBackToFilesClick}
              style={{ cursor: "pointer" }}
            >
              <ArrowLeftIcon className="arrow-left-icon arrow-icon" />
              Back to Files
            </span>
          </Col>
          <Col sm={6}>
            <h4 className="text-center pt-2 fw-bold">
              Procedure Rows Generated:{" "}
            </h4>
            <h5 className="text-center pt-2 fw-bold">{fileUploadName}</h5>
          </Col>
          <Col sm={3} className="pt-2">
            <CustomClickableButton
              variant="outline-primary rounded-pill fw-bold"
              handleOnClick={() => {
                isDownloaded
                  ? checkCreditAndGetCSV(isDownloaded)
                  : validBalance && !isDownloaded
                  ? setShowPopup(true)
                  : checkCreditAndGetCSV(isDownloaded)
              }}
              className="outline-button"
              classNaming="btn btn-primary"
            >
              {isDownloaded
                ? "Download Result"
                : !validBalance
                ? "Purchase Result"
                : !processing
                ? "Purchase Result"
                : "Processing.."}
            </CustomClickableButton>
            {isDownloaded && (
              <p className="text-muted">*This file has been purchased</p>
            )}
          </Col>
        </Row>
        {showPopup && (
          <DownloadPopup
            processing={processing}
            fileUploadName={fileUploadName}
            setProcessing={setProcessing}
            show={showPopup}
            documentId={documentId}
            isSuperFile={isSuperFile}
            onHide={() => setShowPopup(false)}
            setShowPopup={setShowPopup}
          ></DownloadPopup>
        )}
        {showPayPopup && (
          <PopupModal
            user={user}
            fileUploadId={fileUploadId}
            show={showPayPopup}
            onHide={() => setShowPayPopup(false)}
            setShowPayPopup={setShowPayPopup}
            size="lg"
          />
        )}
        <SearchFilter
          handleSearch={handleSearch}
          placeholderText="Search by Code (Enter atleast 2 characters)"
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
        />

        {!!procedureRows?.length ? (
          <>
            <ResultsTable
              procedureRows={procedureRows}
              isSuperFile={isSuperFile}
              setSearchTerm={setSearchTerm}
            />

            <Row className="d-flex flex-row justify-content-center align-items-center">
              <Col sm={1}>
                <div className="d-flex justify-content-center align-items-center">
                  <p className="fw-bold m-auto">Rows per page:</p>
                </div>
              </Col>
              <Col sm={1}>
                <div className="d-flex justify-content-center align-items-center">
                  <Dropdown className="primary">
                    <Dropdown.Toggle
                      variant="outline-primary"
                      className="outline-button  fw-bold"
                      id="record-dropdown"
                    >
                      <span className="fw-bold">
                        {recordPerPage > 0 ? recordPerPage : dataPerPage}
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Header>Select no. of Records</Dropdown.Header>
                      <Dropdown.Item
                        eventKey="1"
                        onClick={() => handleDropdownClick(25)}
                      >
                        25 records
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="2"
                        onClick={() => handleDropdownClick(50)}
                      >
                        50 records
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="3"
                        onClick={() => handleDropdownClick(100)}
                      >
                        100 records
                      </Dropdown.Item>
                      <Dropdown.Item
                        eventKey="4"
                        onClick={() => handleDropdownClick(200)}
                      >
                        200 records
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <Row className="border-top">
            <p className="text-center m-3">No Result Found.</p>
          </Row>
        )}

        {procedureRows?.length > 0 && (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ marginTop: "45px" }}
          >
            {" "}
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              perPage={perPage}
            />
          </div>
        )}
      </Container>
    </div>
  )
}

export default styled(ResultList)`
  .arrow-icon {
    width: 20px;
    heigth: 10px;
  }
`
