import moment from "moment-timezone"
import authentication from "./core/authentication"
import axios from "axios"
import { appConfig } from "./core"

export const openInNewTab = (url: string) => {
  window.open(url, "_blank", "noopener,noreferrer")
}

export const convertTimezoneToESTFromUTC = (utcDt: string) => {
  let timezone: string = "America/Los_Angeles"
  return moment.utc(utcDt).tz(timezone)
}

export const reloadPageAfter2MsDelay = () => {
  setTimeout(() => {
    window.location.reload()
  }, 2000)
}

export const reloadPageAfter30sDelay = () => {
  setTimeout(() => {
    window.location.reload()
  }, 30000)
}
export const toLowerCase = (s: string) => s.toLowerCase()
export const handleViewDocument = async (path: string) => {
  const token = authentication.getAccessToken()
  var uploadedFilePath = new URL(path).pathname.slice(1)
  let res: any = await axios({
    url: `${appConfig.apiUrl}/rest/signed-get-url?path=${uploadedFilePath}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/pdf", // declare the file format in s3
      Authorization: `Bearer ${token}`,
    },
  })
  fetch(`${res.data.url}`, {
    method: "GET",
  })
    .then((response) => {
      if (response.ok) {
        return response.arrayBuffer()
      } else {
        throw new Error("Network response was not ok")
      }
    })
    .then((data) => {
      const blob = new Blob([data], { type: "application/pdf" })
      const url = window.URL.createObjectURL(blob)
      openInNewTab(url)
    })
    .catch((err) => console.error("Error: ", err))
}
