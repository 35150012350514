import styled from "styled-components"
import { Table } from "react-bootstrap"

export interface ResultsTableProps extends React.ComponentProps<any> {
  className?: string
  procedureRows: any
  isSuperFile: boolean
}

const ResultsTable: React.FC<ResultsTableProps> = ({
  className,
  procedureRows,
  isSuperFile,
}) => {
  const tdStyle = {
    border: "3px solid #d205c6",
    width: "300px",
  }
  const tdSuperFileStyle = {
    border: "3px solid #d205c6",
    width: "280px",
  }
  let groupedRows: any
  if (isSuperFile) {
    const groupedData: any = {}
    procedureRows.forEach((procedureRow: any) => {
      const { code, groupNo, amount } = procedureRow
      if (!groupedData[code]) {
        groupedData[code] = {
          code,
          amounts: {},
        }
      }

      groupedData[code].amounts[groupNo] = amount
    })
    groupedRows = Object.values(groupedData)
  }
  return isSuperFile ? (
    <div className={`${className} d-flex justify-content-center`}>
      <Table
        responsive
        bordered
        onContextMenu={(e) => e.preventDefault()}
        className="table-container"
      >
        <thead>
          <tr>
            <th style={tdSuperFileStyle}>CODE</th>
            <th style={tdSuperFileStyle}>FEE SCHEDULE 1 ($)</th>
            <th style={tdSuperFileStyle}>FEE SCHEDULE 2 ($)</th>
            {(groupedRows[0].amounts["GROUP 3"] ||
              groupedRows[1].amounts["GROUP 3"] ||
              groupedRows[2].amounts["GROUP 3"]) && (
              <th style={tdSuperFileStyle}>FEE SCHEDULE 3 ($)</th>
            )}
          </tr>
        </thead>
        <tbody>
          {!!groupedRows &&
            groupedRows.map((groupedRow: any, index: any) => (
              <tr key={index}>
                <td style={tdSuperFileStyle}>{groupedRow.code}</td>
                <td style={tdSuperFileStyle}>
                  {groupedRow.amounts["GROUP 1"] || ""}
                </td>
                <td style={tdSuperFileStyle}>
                  {groupedRow.amounts["GROUP 2"] || ""}
                </td>
                {groupedRow.amounts["GROUP 3"] && (
                  <td style={tdSuperFileStyle}>
                    {groupedRow.amounts["GROUP 3"] || ""}
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  ) : (
    <div className={`${className} d-flex justify-content-center`}>
      <Table
        responsive
        bordered
        onContextMenu={(e) => e.preventDefault()}
        className="table-container"
      >
        <thead>
          <tr>
            <th style={tdStyle}>CODE</th>
            <th style={tdStyle}>FEE($)</th>
          </tr>
        </thead>
        <tbody>
          {procedureRows.map((procedureRow: any, index: number) => (
            <tr key={procedureRow.id}>
              <td style={tdStyle}>{procedureRow?.code}</td>
              <td style={tdStyle}>{procedureRow?.amount}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default styled(ResultsTable)`
  .table-container {
    user-select: none;
    border: "3px solid red";
    text-align: center;
  }
`
