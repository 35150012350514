import React, { useState } from "react"
import { Nav, Navbar, NavDropdown } from "react-bootstrap"
import { FaUserCircle } from "react-icons/fa"
import styled from "styled-components"
import authentication from "../core/authentication"
import { useNavigate } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { GET_USER_CREDIT } from "../graphql/graphql-queries"

export interface NavigationBarProps extends React.ComponentProps<any> {
  className?: string
}

const NavigationBar: React.FC<NavigationBarProps> = ({ className }) => {
  const navigate = useNavigate()
  const [userCredit, setUserCredit] = useState(0)
  const userData = authentication.getUserData()
  const handleOnClickLogout = () => {
    authentication.clear()
    navigate("/login")
  }

  const handleOnClickSettings = () => {
    navigate("/settings")
  }
  useQuery(GET_USER_CREDIT, {
    variables: {
      userId: userData?.id,
    },
    onCompleted: (data) => {
      if (data?.getUserCredit?.credits) {
        setUserCredit(data?.getUserCredit?.credits)
      }
    },
  })
  return (
    <div className={className}>
      <Navbar bg="light" variant="light">
        <div className="navbar-wrapper text-white">
          <Navbar.Brand className="text-white ps-3 fw-bold" href="/">
            <div className="p-0 m-0">
              <img
                src="/Logo.png"
                width="200"
                height="40"
                className="d-inline-block align-items-center"
                alt="FeeSchedulePro logo"
              />
            </div>
          </Navbar.Brand>
          <Navbar.Collapse
            id="justify-content-end"
            className="justify-content-end me-4"
          >
            {authentication?.isSuperAdmin() && (
              <Navbar.Text className="text-white">
                <a href="/test-payment">
                  <b className="text-white me-4">Test Payment</b>
                </a>
              </Navbar.Text>
            )}
            <Navbar.Text className="text-white">
              <a href="/checkout">
                <b className="text-white">Credits</b>
                <span className="text-white fw-bold mx-2">{userCredit}</span>
              </a>
            </Navbar.Text>
          </Navbar.Collapse>

          <NavDropdown
            title={<FaUserCircle size={20} />}
            className="justify-content-end scrollable pe-3"
            pre-scrollable="true"
          >
            <NavDropdown.Item href="/settings" disabled className="fw-bold">
              {userData?.fullName}
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item href="/settings" onClick={handleOnClickSettings}>
              Settings
            </NavDropdown.Item>
            <NavDropdown.Item href="/login" onClick={handleOnClickLogout}>
              Logout
            </NavDropdown.Item>
          </NavDropdown>
        </div>
      </Navbar>
    </div>
  )
}
// add custom.scss
export default styled(NavigationBar)`
  .dropdown-menu {
    max-height: 280px;
    overflow-y: auto;
    left: auto;
    right: 0;
  }

  .bg-danger {
    --bs-bg-opacity: 0.5;
  }
  .navbar {
    padding-top: 0px;
    // padding-bottom: 30px;
    margin-bottom: 30px;
  }
  #new-notification-alert {
    position: absolute;
    padding: 5px;
    margin-top: 2px;
    margin-left: 170px;
    left: 50%;
  }

  .navbar-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: inherit;
    justify-content: space-around;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    text-decoration: none;
    background-color: #253551;
    background-image: radial-gradient(
      circle at top right,
      #441d90 0%,
      #253551 100%
    );
    padding-bottom: 4px;
  }

  .navbar-light .navbar-nav .nav-link {
    color: #000000;

    &:hover {
      color: #13948b;
    }
  }
  .nav-link.active {
    color: #13948b !important;
    // background: black !important;
  }

  #basic-nav-dropdown {
    color: white;
  }
`
